import useStore from "../../../store"
import { useIsMobile } from "../../../hooks/useIsMobile"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import Notes from "../Notes/Notes"
import ReserveInfoBody from "../Reserve/ReserveInfoBody"
import DealerInfo from "./DealerInfo"
import tailwind from "../../../../tailwind.config"
import Spinner from "../../common/Spinner"
import AmDealerUnits from "./AmDealerUnits"
import NearbyDealers from "./NearbyDealers"
import { useEffect } from "react"

export default function AmDealerDashboardSidePanel({ dealerId }) {
  const isMobile = useIsMobile()
  const dealer = useStore((state) => state.dealer)
  const grey700 = tailwind.theme.colors["grey-700"]
  const grey100 = tailwind.theme.colors["grey-100"]
  const fetchNearbyDealers = useStore((state) => state.fetchNearbyDealers)

  useEffect(() => {
    fetchNearbyDealers(dealer?.attributes.lat, dealer?.attributes.lng, '100')
  }, [dealer])

  return (
    <>
    {dealer ?
      <div className="mt-3 w-full">
      <Tabs variant={'soft-rounded'}>
        <TabList>
          {isMobile && <Tab data-testid='info-tab' _selected={{ color: 'white', bg: grey700 }} color={grey100}>Info</Tab>}
          <Tab data-testid='units-tab' _selected={{ color: 'white', bg: grey700 }} color={grey100}>Units</Tab>
          <Tab data-testid='reserves-tab' _selected={{ color: 'white', bg: grey700 }} color={grey100}>Reserve Information</Tab>
          <Tab data-testid='touches-tab' _selected={{ color: 'white', bg: grey700 }} color={grey100}>Notes</Tab>
          <Tab data-testid='touches-tab' _selected={{ color: 'white', bg: grey700 }} color={grey100}>Nearby Dealers</Tab>
        </TabList>
        <TabPanels>
          {isMobile &&
            <TabPanel p={0}>
              <div data-testid='dealer-info'>
                <DealerInfo dealerId={dealerId}/>
              </div>
            </TabPanel>
          }
          <TabPanel p={0}>
            <div data-testid='units'>
              <AmDealerUnits />
            </div>
          </TabPanel>
          <TabPanel p={0}>
            <div data-testid='reserve-information'>
              <ReserveInfoBody />
            </div>
          </TabPanel>
          <TabPanel p={0}>
            <div data-testid='notes' className="bg-grey-900 rounded">
              <Notes type="dealers" id={dealerId} />
            </div>
          </TabPanel>
          <TabPanel p={0}>
            <div data-testid='nearby-dealers' className="bg-grey-900 rounded">
              <NearbyDealers id={dealerId} />
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
      </div> :
      <div className="flex justify-center pt-3 w-full">
        <Spinner />
      </div>
    }
    </>
  )
}