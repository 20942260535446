import { apiUrlV2 } from "../constants/apiUrl"
import createSearchParams from "../utilities/createSearchParams"

const initialState = {
  pendingTransfers: null,
  completedTransfers: null,
  processingTransfers: null,
  transfer: null,
  isFetchingCompletedTransfers: null,
}

const createTransferSlice = (set, get) => ({
  ...initialState,
  fetchCompletedTransfers: (params) => {
    set(() => ({ isFetchingCompletedTransfers: true }))
    fetch(
      `${apiUrlV2}/transfers?filter[scope]=completed&${createSearchParams(params)}`,
      { headers: get().requestHeaders }
    )
      .then(response => response.json())
      .then(data => set((state) => ({ completedTransfers: data.data, isFetchingCompletedTransfers: false })))
  },
  fetchPendingTransfers: () => fetch(
    `${apiUrlV2}/transfers?filter[scope]=pending`,
    { headers: get().requestHeaders }
  )
    .then(response => response.json())
    .then(data => set((state) => ({ pendingTransfers: data.data }))),
  fetchProcessingTransfers: () => fetch(
    `${apiUrlV2}/transfers?filter[scope]=processing`,
    { headers: get().requestHeaders }
  )
    .then(response => response.json())
    .then(data => set((state) => ({ processingTransfers: data.data }))),
  cancelTransfer: (id) => fetch(
    `${apiUrlV2}/transfers/${id}/cancel-transfer`, {
      method: 'PUT',
      headers: get().requestHeaders,
    }
  ),
  setTransfer: (transfer) => set({ transfer: transfer }),
  resetTransferSlice: () => set(initialState)
})

export default createTransferSlice