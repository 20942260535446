import { apiUrlV2 } from "../constants/apiUrl"

const initialState = {
  touches: [],
  isCreatingTouch: false,
}

const createTouchesSlice = (set, get) => ({
  ...initialState,
  fetchTouches: async (id) => {
    try {
      const response = await fetch(
        `${apiUrlV2}/dealers/${id}/touches`,
        { headers: get().requestHeaders }
      )
      const data = await response.json()
      set(() => ({ touches: data.data }))
    } catch (err) {
      get().setErrMessage('Unable to retrieve touches at this time.')
    }
  },
  createTouch: async (id, body) => {
    set(() => ({ isCreatingTouch: true }))
    try {
      const response = await fetch(
        `${apiUrlV2}/dealers/${id}/touches`,
        {
          headers: get().requestHeaders,
          method: 'POST',
          body: JSON.stringify(body)
        }
      )
      const data = await response.json()
      console.log(data)

      if (response.status === 200) {
        get().setSuccessMessage('Touch successfully created')
        get().fetchTouches(id)
      } else {
        get().setErrMessage(data.errors[0])
      }
    } catch (err) {
      get().setErrMessage('There was an error creating this touch. Please try again.')
    } finally {
      set(() => ({ isCreatingTouch: false }))
    }
  },
  resetTouchesSlice: () => set(initialState)
})

export default createTouchesSlice