import useStore from "../../../store"
import AlertMessage from "../../common/AlertMessage"

export default function PaymentSuccess() {
  const payment = useStore((state) => state.payment)

  if (payment.data) {
    return(       
      <AlertMessage message={'Your payment has been submitted.'} />
    )
  } else {
    return(
      <AlertMessage message={payment[0].message} />
    )
  }
}