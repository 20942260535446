import { useEffect, useState } from 'react'
import useStore from '../../store'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CreditUtilization from './CreditUtilization/CreditUtilization'
import CurrentUnits from './CurrentUnits/CurrentUnits'
import RegisterBiometric from '../RegisterBiometric/RegisterBiometric'
import formatter from '../../constants/currencyFormatter'
import { useParams } from 'react-router-dom'
import DealerInfo from '../AccountManager/Dealer/DealerInfo'
import ReserveClickableRow from '../AccountManager/Reserve/ReserveClickableRow'
import DealerNameHeader from '../AccountManager/Dealer/DealerNameHeader'
import Spinner from '../common/Spinner'
import DashboardContainer from '../common/DashboardContainer'
import UnitPageBody from '../Dealer/UnitPage/UnitPageBody'
import ReserveInfoBody from '../AccountManager/Reserve/ReserveInfoBody'
import NotesClickableRow from '../AccountManager/Notes/NotesClickableRow'
import Notes from '../AccountManager/Notes/Notes'
import UnitsNeedingAttention from '../AccountManager/Dealer/UnitsNeedingAttention'

export default function DealerDashboard() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { dealerId } = useParams()
  const user = useStore((state) => state.user)
  const dealer = useStore((state) => state.dealer)
  const fetchUnits = useStore((state) => state.fetchUnits)
  const fetchUnitsAsAm = useStore((state) => state.fetchUnitsAsAm)
  const fetchDealer = useStore((state) => state.fetchDealer)
  const dashboardUnitId = useStore((state) => state.dashboardUnitId)
  const setDashboardUnitId = useStore((state) => state.setDashboardUnitId)
  const askRegisterBiometric = useStore((state) => state.askRegisterBiometric)
  const setActionButton = useStore((state) => state.setActionButton)
  const fetchBankAccountsAsAm = useStore((state) => state.fetchBankAccountsAsAm)
  const dealerFetching = useStore((state) => state.dealerFetching)
  const dealerDashboardSidePanel = useStore((state) => state.dealerDashboardSidePanel)
  const amUnitsTableParams = useStore((state) => state.amUnitsTableParams)
  const setDealerDashboardSidePanel = useStore((state) => state.setDealerDashboardSidePanel)
  const unitsNeedingAttention = Object.values({
    untouched: dealer?.attributes['untouched-unit-count'],
    sold: dealer?.attributes['sold-unit-count'],
    repo: dealer?.attributes['repo-unit-count'],
    pastDue: dealer?.attributes['past-due-unit-count'],
  }).some(count => count > 0)

  useEffect(() => {
    if (dealerId) {
      fetchDealer(dealerId)
      fetchUnitsAsAm(dealerId, amUnitsTableParams)
      fetchBankAccountsAsAm(dealerId)
    } else {
      fetchDealer(user.dealerId)
      fetchUnits()
    }

    setDealerDashboardSidePanel(null)
  }, [amUnitsTableParams])

  useEffect(() => {
    setActionButton({
      buttonText: t('Request Floor'),
      altTextHeader: t("Credit Available"),
      altTextBody: dealer ? formatter.format(dealer.attributes['total-credit-remaining']) : null,
      onClick: () => navigate('/blackbook'),
    })

    return () => setActionButton(null)
  }, [dealer])

  const onSidePanelClose = () => {
    setDealerDashboardSidePanel(null)
    setDashboardUnitId(null)
  }

  const sidePanels = [
    {
      isExpanded: !dashboardUnitId && dealerDashboardSidePanel === 'reserve info',
      component: <ReserveInfoBody/>,
      onClose: onSidePanelClose,
      isFullscreenable: false,
    },
    {
      isExpanded: dashboardUnitId,
      component: <UnitPageBody unitId={dashboardUnitId} />,
      onClose: onSidePanelClose,
      isFullscreenable: false,
    },
    {
      isExpanded: !dashboardUnitId && dealerDashboardSidePanel === 'notes',
      component: <div className="pt-2"><Notes type='dealers' id={dealer?.id} /></div>,
      onClose: onSidePanelClose,
      isFullscreenable: false,
    }
  ]

  return (
    <DashboardContainer sidePanels={sidePanels}>
      {askRegisterBiometric && user.type === 'DealerUser' && <RegisterBiometric />}
        {user.type === 'AccountManager' &&
          <>
            {dealerFetching ?
              <div className="flex w-full justify-center">
                <Spinner />
              </div>
            :
              <DealerNameHeader
                name={dealer?.attributes.name ? dealer.attributes.name : 'No Name'}
              />
            }
            {unitsNeedingAttention && <UnitsNeedingAttention/>}
            <DealerInfo dealerId={dealerId}/>
            <ReserveClickableRow
              onClick={() => {
                setDealerDashboardSidePanel(dealerDashboardSidePanel === 'reserve info' ? null : 'reserve info')
                setDashboardUnitId(null)
              }}
              isExpanded={dealerDashboardSidePanel === 'reserve info'}
            />
            <NotesClickableRow
              onClick={() => {
                setDealerDashboardSidePanel(dealerDashboardSidePanel === 'notes' ? null : 'notes')
                setDashboardUnitId(null)
              }}
              isExpanded={dealerDashboardSidePanel === 'notes'}
            />
          </>
        }
        <CreditUtilization />
        <CurrentUnits />
    </DashboardContainer>
  )
}
