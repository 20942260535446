import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { get, set, del } from 'idb-keyval'
import createActionCableSlice from './slices/actionCableSlice'
import createAuthSlice from './slices/authSlice'
import createBankAccountSlice from './slices/bankAccountSlice'
import createBlackbookSlice from './slices/blackbookSlice'
import createBranchSlice from './slices/branchSlice'
import createCometChatSlice from './slices/cometChatSlice'
import createDealerSlice from './slices/dealerSlice'
import createDealerUnitsSlice from './slices/dealerUnitsSlice'
import createGoogleSlice from './slices/googleSlice'
import createNapSlice from './slices/napSlice'
import createPaymentSlice from './slices/paymentSlice'
import createRemindersSlice from './slices/remindersSlice'
import createRiskMitigationSlice from './slices/riskMitigationSlice'
import createTransferSlice from './slices/transferSlice'
import createUnitsSlice from './slices/unitsSlice'
import createUserSlice from './slices/userSlice'
import createDealersSlice from './slices/dealersSlice'
import createDailyOpsSlice from './slices/dailyOpsSlice'
import resetAllSlices from './slices/resetAllSlices'
import createBusinessDevSlice from './slices/businessDevSlice'
import createApplicationsSlice from './slices/applicationsSlice'
import createNotesSlice from './slices/notesSlice'
import createPeopleSlice from './slices/peopleSlice'
import createNotificationsSlice from './slices/notificationsSlice'
import createDealerDocumentsSlice from './slices/dealerDocumentsSlice'
import createTouchesSlice from './slices/touchesSlice'
import createInAppMessagingSlice from './slices/inAppMessagingSlice'
import createDealersListSlice from './slices/dealersListSlice'

const storage = {
  getItem: async (name) => {
    return (await get(name)) || null
  },
  setItem: async (name, value) => {
    await set(name, value)
  },
  removeItem: async (name) => {
    await del(name)
  },
}

const useStore = create(
  persist(
    (...a) => ({
      // When adding new slices, be sure to add a reset slice method to the resetAllSlices file
      // so that data is cleared on user logout.
      ...createActionCableSlice(...a),
      ...createApplicationsSlice(...a),
      ...createAuthSlice(...a),
      ...createBankAccountSlice(...a),
      ...createBlackbookSlice(...a),
      ...createBranchSlice(...a),
      ...createBusinessDevSlice(...a),
      ...createCometChatSlice(...a),
      ...createDailyOpsSlice(...a),
      ...createDealerDocumentsSlice(...a),
      ...createDealersListSlice(...a),
      ...createDealerSlice(...a),
      ...createDealersSlice(...a),
      ...createDealerUnitsSlice(...a),
      ...createGoogleSlice(...a),
      ...createNapSlice(...a),
      ...createNotesSlice(...a),
      ...createNotificationsSlice(...a),
      ...createPaymentSlice(...a),
      ...createPeopleSlice(...a),
      ...createRemindersSlice(...a),
      ...createRiskMitigationSlice(...a),
      ...createTouchesSlice(...a),
      ...createTransferSlice(...a),
      ...createUnitsSlice(...a),
      ...createUserSlice(...a),
      ...createInAppMessagingSlice(...a),
      ...resetAllSlices(...a)
    }),
    {
      name: 'data-storage',
      partialize: (state) => Object.fromEntries(
        // Add to array to filter out any keys we don't want to persist in state
        // eg, Object.entries(state).filter(([key]) => !['keys', 'to', 'exclude'].includes(key)),
        Object.entries(state).filter(([key]) => !['consumer'].includes(key)),
      ),
      storage: createJSONStorage(() => storage)
    }
  )
)

export default useStore

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Store', useStore);
}