import { Card, CardHeader, CardBody, CardFooter, Text, Grid, Box } from '@chakra-ui/react'
import useStore from '../../../../store'
import DealerName from '../DealerName'
import tailwind from '../../../../../tailwind.config'
import { TiDelete } from "react-icons/ti"

export default function DealerCard ({dealer}) {
  const deepOcean = tailwind.theme.colors["deep-ocean"]
  const red = tailwind.theme.colors["fpx-red"]
  const removeListDealer = useStore((state) => state.removeListDealer)

  return(
    <Card m={4} bg={deepOcean}>
     <CardBody>
       <Grid templateColumns="1fr 2fr auto" gap={4} alignItems="center">
         <Box maxWidth="200px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
           <DealerName dealer={dealer} key={dealer.id} />
         </Box>
         <Grid templateColumns="repeat(3, 1fr)" gap={4}>
           <Text>Util: {dealer.attributes['current-utilization']}%</Text>
           <Text>NAPs: {dealer.attributes['nap-count']}</Text>
           <Text>Units: {dealer.attributes['unit-count']}</Text>
         </Grid>
         <TiDelete
           color={red}
           size={'30px'}
           aria-label="Delete list dealer"
           onClick={() => removeListDealer(dealer.id)}
           cursor={'pointer'}
         />
       </Grid>
     </CardBody>
    </Card>
  )
}