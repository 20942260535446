import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
} from '@chakra-ui/react'
import FileRow from './FileRow'
import useStore from '../../../../store'
import tailwind from '../../../../../tailwind.config'
import { useState, useEffect } from 'react'
import FileDetails from './FileDetails'
import Spinner from '../../../common/Spinner'
import FilesTableHeader from './FilesTableHeader'

export default function FilesTable({dealerId, dealerFiles, tableTitle, fileType}) {
  const grey300 = tailwind.theme.colors["grey-300"]
  const [selectedFile, setSelectedFile] = useState(null)
  const fetchDealerDocuments = useStore((state) => state.fetchDealerDocuments)
  const fetchDealerFile = useStore((state) => state.fetchDealerFile)
  const dealerFilesFetching = useStore((state) => state.dealerFilesFetching)
  const fetchFolderList = useStore((state) => state.fetchFolderList)
  const fetchLotPhotos = useStore((state) => state.fetchLotPhotos)

  useEffect(() => {
    setSelectedFile(null)
    fetchFolderList(dealerId)
  }, [dealerFiles])

  return(
    <div className="h-full pl-2">
      <div className="relative flex flex-col">
        {selectedFile ?
          <FileDetails
            onBack={() => {
              setSelectedFile(null)
              fetchDealerDocuments(dealerId)
              fetchLotPhotos(dealerId)
            }}
            fileId={selectedFile.id}
            dealerId={dealerId}
            setSelectedFile={setSelectedFile}
          />
          :
          <div>
          <FilesTableHeader tableTitle={tableTitle} dealerId={dealerId} modalTitle={'Add a File'} fileType={fileType}/>
          {!dealerFilesFetching ?
            <TableContainer>
              <Table variant='simple'>
                <Thead>
                  <Tr>
                    <Th color={grey300}>File</Th>
                    <Th color={grey300}>Folder</Th>
                    <Th color={grey300}>Date Added</Th>
                    <Th color={grey300}>Added By</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {dealerFiles.map((file) => (
                    <FileRow key={file.id} file={file} dealerId={dealerId} onClick={() => {
                      setSelectedFile(file)
                      fetchDealerFile(file.id, dealerId)
                    }}/>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          :
          <Spinner/>
          }
          </div>
        }
      </div>
    </div>
  )
}