import useStore from "../../../../store"
import { useState } from "react"
import { motion } from "framer-motion"
import { Box, FormControl, FormLabel, Textarea } from '@chakra-ui/react'
import { HappyButton, NeutralButton } from "../../../common/Buttons"
import Spinner from "../../../common/Spinner"
import tailwind from "../../../../../tailwind.config"
import { typeEnum, purposeEnum, responseEnum } from "../../../../constants/touchEnums"
import SelectDropdown from '../../../common/SelectDropdown'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import BackButton from "../../../common/BackButton"

export default function TouchForm({ dealerId, setShowTouchForm }) {
  const createTouch = useStore((state) => state.createTouch)
  const isCreatingTouch = useStore((state) => state.isCreatingTouch)
  const user = useStore((state) => state.user)
  const setErrMessage = useStore((state) => state.setErrMessage)
  const [inputValues, setInputValues] = useState({
    type: null,
    purpose: null,
    response: null,
    note: null,
    follow_up_on: null,
    reminder: null,
    reminder_for_id: user.userId,
  })
  const [formErrors, setFormErrors] = useState([])
  const grey300 = tailwind.theme.colors["grey-300"]

  const handleSubmit = async () => {
    if (!inputValues.type || !inputValues.purpose) {
      setErrMessage('Type and Purpose cannot be blank')
      setFormErrors(Object.keys(inputValues).filter((key) =>
        (key === 'type' || key === 'purpose') && !inputValues[key]
      ))
    } else {
      await createTouch(dealerId, inputValues)
      setShowTouchForm(false)
    }
  }

  return(
    <div className="overflow-x-hidden bg-grey-900 rounded-b">
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        transition={{ type: 'tween', duration: 0.2 }}
      >
        <BackButton onClick={() => setShowTouchForm(false)} />

        <Box as="form" mt={2} ml={4} onSubmit={handleSubmit}>
          <FormControl id="type" mb={2} onClick={() => setFormErrors([])}>
            <SelectDropdown
              label="Type of Touch"
              options={Object.keys(typeEnum)}
              defaultText={'Select type'}
              selectedItem={inputValues.type}
              setSelectedItem={(item) => setInputValues({ ...inputValues, type: item })}
              displaySelectedItem={(item) => typeEnum[item]}
              isErrored={formErrors.includes('type')}
              testId="type-select"
            />
          </FormControl>

          <FormControl id="purpose" mb={2} onClick={() => setFormErrors([])}>
            <SelectDropdown
              label="Purpose of Touch"
              options={Object.keys(purposeEnum)}
              defaultText={'Select purpose'}
              selectedItem={inputValues.purpose}
              setSelectedItem={(item) => setInputValues({ ...inputValues, purpose: item })}
              displaySelectedItem={(item) => purposeEnum[item]}
              isErrored={formErrors.includes('purpose')}
              testId="purpose-select"
            />
          </FormControl>

          <FormControl id="response" mb={2}>
            <SelectDropdown
              label="Response"
              options={Object.keys(responseEnum)}
              defaultText={'Select response'}
              selectedItem={inputValues.response}
              setSelectedItem={(item) => setInputValues({ ...inputValues, response: item })}
              displaySelectedItem={(item) => responseEnum[item]}
              isErrored={false}
              testId="response-select"
            />
          </FormControl>

          <FormControl id="note" mb={2}>
            <FormLabel fontSize={'sm'} color={grey300} htmlFor="note">Note</FormLabel>
            <Textarea
              id="note"
              value={inputValues.note}
              onChange={(e) => setInputValues({ ...inputValues, note: e.target.value })}
              focusBorderColor="white"
              p={1}
            />
          </FormControl>

          <FormControl id="follow up on" mb={2} className="flex flex-col">
            <FormLabel fontSize={'sm'} color={grey300} htmlFor="follow up on">Remind me on...</FormLabel>
            <DatePicker
              id="follow up on"
              selected={inputValues.follow_up_on}
              onChange={(date) => setInputValues({ ...inputValues, follow_up_on: date })}
              dateFormat="M/d/yy"
              className="px-1 py-0 w-full bg-grey-900 border-2 border-grey-700 focus:border-grey-300 focus:ring-0 rounded"
              showPopperArrow={false}
            />
          </FormControl>

          <FormControl id="reminder" mb={2}>
            <FormLabel fontSize={'sm'} color={grey300} htmlFor="reminder">Remind me to...</FormLabel>
            <Textarea
              id="reminder"
              value={inputValues.reminder}
              onChange={(e) => setInputValues({ ...inputValues, reminder: e.target.value })}
              focusBorderColor="white"
              p={1}
            />
          </FormControl>

          <div className="flex justify-evenly items-center">
            {isCreatingTouch ?
              <Spinner color="green" /> :
              <>
                <HappyButton
                  text='Create Touch'
                  onClick={handleSubmit}
                  style="my-4 w-30 justify-items-center"
                />
                <NeutralButton
                  text='Cancel'
                  onClick={() => setShowTouchForm(false)}
                  style="my-4 w-30 justify-items-center"
                />
              </>
            }
          </div>
        </Box>

      </motion.div>
    </div>
  )
}