import { FaRegQuestionCircle  } from "react-icons/fa"
import { useNavigate } from 'react-router-dom'
import useStore from "../../store"
import FxLogo from "../common/FxLogo"
import FullFxLogo from "../common/FullFxLogo"
import OfflineAlert from "../common/OfflineAlert"
import NotificationsBell from "./NotificationsBell"
import NavBarBackButton from "./NavBarBackButton"
import { reminderOverdue } from "../../utilities/reminderUtilities"

export default function MobileTopNav() {
  const navigate = useNavigate()
  const user = useStore((state) => state.user)
  const reminders = useStore((state) => state.reminders)
  const notifications = useStore((state) => state.notifications)

  function renderLogo() {
    if (user?.type === "AccountManager") {
      return <FxLogo />
    } else if (user?.type === "DealerUser") {
      return <FullFxLogo />
    }
  }

  return(
    <div className={`lg:hidden relative flex items-center justify-between w-full h-11 px-3 text-grey-300`}>
      <div className={`flex text-lg h-full items-center bg-black`}>
        <NavBarBackButton />
        <OfflineAlert />
      </div>

      {renderLogo()}

      {user?.type === "DealerUser" &&
        <div className="flex">
          <div
            className="text-xl mr-3"
            onClick={() => navigate('/customer_support')}
            data-testid="support-button"
          >
            <FaRegQuestionCircle />
          </div>

          <NotificationsBell
            isOnAlert={notifications?.some((n) => n.attributes['read-at'] === null)}
            onClick={() => navigate('/notifications')}
          />
        </div>
      }

      {user?.type === 'AccountManager' &&
        <div className="flex">
          <NotificationsBell
            isOnAlert={reminders?.some((r) => reminderOverdue(r))}
            onClick={() => navigate('/reminders')}
          />
        </div>

      }

    </div>
  )
}