import useStore from "../../../../store"
import tailwind from "../../../../../tailwind.config"
import { GiSaveArrow } from "react-icons/gi"
import { MdOutlineDone } from "react-icons/md"

export default function SaveDealerIcon({dealer}) {
  const savedDealers = useStore((state) => state.savedDealers)
  const green = tailwind.theme.colors["green"]
  const createListDealer = useStore((state) => state.createListDealer)
  const isDealerSaved = savedDealers.some((savedDealer) => savedDealer.id === dealer.id)

  const handleSaveToList = (event) => {
    event.stopPropagation()
    createListDealer(dealer.id)
  }

  return(
    <>
      {isDealerSaved ?
        <MdOutlineDone className="mr-2" data-testid="checkmark-icon" color={green}/>
        :
        <GiSaveArrow className="mr-2" data-testid="save-icon" onClick={handleSaveToList} />
      }
    </>
  )
}