import useStore from "../../../store"
import UnitRow from "./UnitRow"
import unitFilter from "../../../utilities/unitFilter"
import AlertMessage from "../../common/AlertMessage"
import Spinner from "../../common/Spinner"

export default function UnitTable({searchTerm, setSearchTerm}) {
  const units = useStore((state) => state.units)
  const filteredUnits = unitFilter(units, searchTerm)
  const unitsFetching = useStore((state) => state.unitsFetching)

  const matchesFilter = (unit, parameter) => {
    return unit.attributes[parameter]?.toLowerCase().includes(searchTerm.toLowerCase())
  }

  const matchedAttribute = (unit) => {
    if (searchTerm.length < 3) {
      return null
    } else if (matchesFilter(unit, 'vin')) {
      return 'VIN'
    } else if (matchesFilter(unit, 'color')) {
      return 'Color'
    } else {
      return null
    }
  }

  return (
    <div className="w-full bg-grey-900 rounded">
      <div className="flex flex-col items-center w-full lg:h-full p-2 bg-grey-900 rounded-b">
        {filteredUnits.length == 0 ?
          <div><div><AlertMessage message="No results found" /></div></div> :
          filteredUnits.map((unit) => <UnitRow unit={unit} key={unit.id} matchedAttribute={matchedAttribute(unit)} filter={searchTerm}/>)
        }
      </div>
    </div>
  )
}
