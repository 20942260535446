import ClickableRow from "../../common/ClickableRow"
import { DateTime } from "luxon"
import { isStringOnlyWhitespace } from "../../../utilities/stringUtilities"

export default function NotesRow({ note, onClick }) {
  const date = DateTime.fromISO(note.attributes['created-at'])

  return(
    <ClickableRow
      onClick={onClick}
    >
      <div
        className="flex flex-col truncate w-full"
        data-testid="note-row"
      >
        <div className='text-sm truncate text-ellipsis'>
          {`${date.toFormat('M/d/yy')}`}
          {note.attributes['created-by'] && ` | ${note.attributes['created-by']}`}
          {note.attributes.subject && ` | ${note.attributes.subject}` }
        </div>
        <div className="w-full truncate text-ellipsis text-grey-100">
          {note.attributes.note && !isStringOnlyWhitespace(note.attributes.note) ? note.attributes.note : note.attributes.subject}
        </div>
      </div>
    </ClickableRow>
  )
}