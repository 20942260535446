import useStore from "../../../store"
import { useEffect } from "react"
import Spinner from "../../common/Spinner"
import NotesBody from "./NotesBody"

// Notes type is either "dealers", "units", or "audits"
export default function Notes({ type, id }) {
  const fetchNotes = useStore((state) => state.fetchNotes)
  const notes = useStore((state) => state.notes)
  const notesPage = useStore((state) => state.notesPage)

  useEffect(() => {
    fetchNotes(type, id)
  }, [type, id, notesPage])

  const parentRelationshipId = () => {
    if (['dealers', 'units', 'audits'].includes(type)) {
      return notes[0]?.attributes[type.slice(0, -1)]?.id
    } else {
      return null
    }
  }

  return(
    <div className="h-full">
      <div className="relative flex flex-col bg-grey-900 rounded">
        {notes && Number(parentRelationshipId()) === Number(id) ?
          <NotesBody type={type} id={id} /> :
          <div className="flex justify-center w-full">
            <Spinner />
          </div>
        }
      </div>
    </div>
  )
}