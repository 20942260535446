import { BiError } from "react-icons/bi"
import { Tooltip } from 'react-tooltip'
import { FaCaretUp, FaCaretDown } from 'react-icons/fa'

export default function KpiMetric({metric, timeInterval}) {
  const arrowDirection = (stat) => {
    if (stat > 0 || stat > '0%') {
      return { icon: <FaCaretUp />, className: 'bg-green text-white rounded-md bg-opacity-70' }
    } else if (stat < 0 || stat < '0%') {
      return { icon: <FaCaretDown />, className: 'bg-fpx-red text-white rounded-md bg-opacity-70' }
    }
    return { icon: null, className: 'text-grey-100 ml-4' }
  }

  const kpiDisplay = () => {
    if (metric != 'null%' && metric != null) {
      return(
        <div data-testid="metric-value" className={`flex items-center justify-center ${arrowDirection(metric).className}`}>
          {arrowDirection(metric).icon} {`${metric} ${timeInterval}`}
        </div>
      )
    } else {
      return(
        <div className="flex items-center justify-center">
            <BiError size={'22'} data-tooltip-id="kpi_metric"/>
            <Tooltip id="kpi_metric" place="top" content="Unable to fetch data. Please come back later."/>
        </div>
      )
    }
  }

  return(
    kpiDisplay()
  )
}
