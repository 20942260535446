import { apiUrl, apiUrlV2 } from "../constants/apiUrl"
import formatDateForApi from "../utilities/formatDateForApi"

const initialState = {
  dealer: null,
  dealerReserveInfo: null,
  dealerReserveInfoFetching: false,
  dealerStats: null,
  dealerFetching: false,
  prospectDealerId: null,
  dealerDashboardSidePanel: null,
}

const createDealerSlice = (set, get) => ({
  ...initialState,
  fetchDealer: (dealerId, useApiUrlV2 = null) => {
    set((state) => ({ dealerFetching: true}))
    try {
      fetch(
        `${useApiUrlV2 ? apiUrlV2 : apiUrl}/dealers/${dealerId}`, 
        { headers: get().requestHeaders }
      )
        .then(response => response.json())
        .then(data => set((state) => ({ dealer: data.data, dealerFetching: false })))
    } catch (err) {
      get().setErrMessage('Unable to fetch dealer. Please try again')
    }
  },
  fetchDealerStats: (headers) => fetch(
    `${apiUrlV2}/dealers/daily_dealer_principal`, 
    { headers: headers}
  )
    .then(response => response.json())
    .then(data => { 
      if (data.errors) {
        set((state) => ({ dealerStats: null }))
      } else {
        set((state) => ({ 
        dealerStats: data.data.map(
            (stat) => ({...stat, principal: Number(stat.principal)})
          )
        }))
      }
    }),
  fetchBaseRateFees: () => fetch(
    `${apiUrl}/dealer/base-rate-fees?date=${formatDateForApi(new Date)}`,
    { headers: get().requestHeaders }
  ),
  updateDealer: async (dealerId, attributes) => {
    try {
      const response = await fetch(
        `${apiUrlV2}/dealers/${dealerId}`,
        {
          method: 'PATCH',
          headers: get().requestHeaders,
          body: JSON.stringify({
            dealer: attributes
          })
        }
      )
      if (response.status === 200) {
        const data = await response.json()
        set((state) => ({ dealer: data.data }))
      }
    } catch (err) {
      get().setErrMessage('Unable to update dealer. Please try again')
    }
  },
  suspendDealer: async (dealerId) => {
    set((state) => ({ dealerFetching: true}))
    try {
      const response = await fetch(
        `${apiUrlV2}/dealers/${dealerId}/suspend`,
        {
          method: 'PATCH',
          headers: get().requestHeaders
        }
      )
      if (response.status === 200) {
        const data = await response.json()
        set((state) => ({ dealer: data.data, dealerFetching: false }))
      }
    } catch(err) {
      get().setErrMessage('Unable to suspend dealer. Please try again')
    }
  },
  unSuspendDealer: async (dealerId) =>{
    set((state) => ({ dealerFetching: true}))

    try {
      const response = await fetch(
        `${apiUrlV2}/dealers/${dealerId}/unsuspend`,
        {
          method: 'PATCH',
          headers: get().requestHeaders
        }
      )
      if(response.status === 200) {
        const data = await response.json()
        set((state) => ({ dealer: data.data, dealerFetching: false }))
      }
    } catch(err) {
      get().setErrMessage('Unable to suspend dealer. Please try again')
    }
  },
  setProspectDealerId: (id) => {set(() => ({prospectDealerId: id}))},
  setDealerDashboardSidePanel: (panel) => set(() => ({ dealerDashboardSidePanel: panel })),
  resetDealerSlice: () => set(initialState)
})

export default createDealerSlice