import useStore from "../../../store"
import Spinner from "../../common/Spinner"
import UnitTable from "./UnitTable"
import NoCurrentUnits from "./NoCurrentUnits"
import { useState } from "react"
import SearchBar from "../../common/SearchBar"
import UnitTableFilterButtons from "../../AccountManager/Dealer/Units/UnitTableFilterButtons"
import { useTranslation } from "react-i18next"
import { FaUndoAlt } from "react-icons/fa"

export default function CurrentUnits() {
  const units = useStore((state) => state.units)
  const unitsFetching = useStore((state) => state.unitsFetching)
  const user = useStore((state) => state.user)
  const { t } = useTranslation()
  const amUnitsTableParams = useStore((state) => state.amUnitsTableParams)
  const setAmUnitsTableParams = useStore((state) => state.setAmUnitsTableParams)
  const dealerUnitsSearchBarValue = useStore((state) => state.dealerUnitsSearchBarValue)
  const setDealerUnitsSearchBarValue = useStore((state) => state.setDealerUnitsSearchBarValue)
  const dealer = useStore((state) => state.dealer)


  const unitsBody = () => {
    if (units?.length > 0) {
      if(units[0]?.attributes['dealer-id'] == dealer?.id) {
        return (<UnitTable searchTerm={dealerUnitsSearchBarValue} setSearchTerm={setDealerUnitsSearchBarValue}/>)
      } else {
        return (<Spinner />)
      }
    } else if (unitsFetching) {
      return (<Spinner />)
    } else {
      return (<NoCurrentUnits />)
    }
  }

  const handleReset = () => {
    setAmUnitsTableParams('')
    setDealerUnitsSearchBarValue('')
  }

  const currentScope = amUnitsTableParams['filter[scope]']

  return (
    <div className="flex flex-col items-center sm:mt-0 w-full bg-grey-900">
      <div className="w-full sticky z-30 top-0 left-0 w-full font-bold text-grey-100 text-left rounded-t bg-grey-900">
        <div className="flex w-full items-center pt-2 pb-3 px-2">
          <div className="font-header font-semibold text-md text-green mr-1">
            {user.type === "AccountManager" ? "Units" : t("Current Units")}
          </div>
        </div>
        <div className="flex w-full items-center pb-2 px-2">
            {((currentScope && currentScope !== "current") || (user.type === 'AccountManager' && currentScope === "current" && unitsFetching) || dealerUnitsSearchBarValue) &&
              (
                <div className="relative w-8 h-8 flex-shrink-0">
                  <div className="absolute inset-0 flex items-center justify-center" data-testid={unitsFetching ? "loading-spinner" : "undo-filter"}>
                    {(unitsFetching && user.type === 'AccountManager') ? (
                      <Spinner color="fpx-red" height="15px" />
                    ) : (
                      <FaUndoAlt className="cursor-pointer" onClick={handleReset} />
                    )}
                  </div>
                </div>
              )
            }
          <div className="grow">
            <SearchBar onChange={setDealerUnitsSearchBarValue} value={dealerUnitsSearchBarValue} />
          </div>
        </div>
      </div>

      {user?.type === "AccountManager" && <UnitTableFilterButtons />}
      {unitsBody()}
    </div>
  )
}
