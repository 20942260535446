import { FaSave } from "react-icons/fa"
import { useState } from "react"
import SelectDropdown from "./SelectDropdown"
import { FaEdit } from "react-icons/fa"

export default function DropdownInfoItem({ title, data, dropdownOptions, handleSave, setIsEditing, isEditing }) {
  const [inputValue, setInputValue] = useState(data)

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const handleInputChange = (newStatus) => {
    setInputValue(newStatus)
  }

  return (
    <div className="p-1">
      <p className="text-sm text-grey-300">{title}</p>
      {isEditing ? (
        <div className="flex items-center">
          <SelectDropdown
            options={dropdownOptions}
            selectedItem={inputValue}
            setSelectedItem={handleInputChange}
            defaultText="Change Status"
            displaySelectedItem={(item) => item}
            data-testid="select-dropdown"
          />
          <FaSave onClick={() => { handleSave(inputValue) }} className="ml-2 text-green-500 cursor-pointer" data-testid="save-icon" />
        </div>
      ) : (
        <div className="flex items-center">
          <p className="text-md text-grey-100">{data}</p>
          <FaEdit onClick={handleEditClick} className="ml-2 text-blue-500 cursor-pointer" data-testid="edit-icon" />
        </div>
      )}
    </div>
  )
}
