import useStore from "../../../../store"
import { Button, SimpleGrid } from "@chakra-ui/react"
import tailwind from "../../../../../tailwind.config"

export default function UnitTableFilterButtons () {
  const setAmUnitsTableParams = useStore((state) => state.setAmUnitsTableParams)
  const amUnitsTableParams = useStore((state) => state.amUnitsTableParams)
  const grey700 = tailwind.theme.colors["grey-700"]
  const grey100 = tailwind.theme.colors["grey-100"]

  const handleSortUnits = (params) => {
    const currentScope = amUnitsTableParams['filter[scope]']

    if (currentScope === params) {
      setAmUnitsTableParams('')
    } else {
      setAmUnitsTableParams({
        [`filter[scope]`]: params
      })
    }
  }

  const buttonsList = {
    "Untouched 40+ Days": "untouched_40_plus_days",
    "Past Due": "past_due",
    "Repos": "repo",
    "Sold": "sold"
  }

  return(
    <div className="w-full flex justify-between px-2 py-2 bg-grey-900">
      <SimpleGrid columns={{ base: 2, md: 4 }} spacing={2}>
        {Object.entries(buttonsList).map(([label, scope]) => {
          const isActive = amUnitsTableParams["filter[scope]"] === scope
          return (
            <Button
              key={scope}
              color={isActive ? grey700 : grey100}
              bg={isActive ? grey100 : grey700}
              onClick={() => handleSortUnits(scope)}
              data-testid={scope}
              borderRadius="full"
              sx={{
                _hover: {
                  bg: isActive ? grey100 : grey700,
                  color: "white",
                  textColor: isActive ? grey700 : grey100,
                  opacity: "75%",
                },
              }}
            >
              {label}
            </Button>
          );
        })}
      </SimpleGrid>
    </div>
  )
}