import {
  Divider,
  Stack,
  Checkbox,
  Box,
} from "@chakra-ui/react"
import { useState } from "react"
import useStore from "../../../../store"
import UtilizationFilter from "./UtilizationFilter"

export default function FilterOptions({setDealerTableFilterParams, dealerTableFilterParams}) {
  const [selectedLabel, setSelectedLabel] = useState('')
  const setSelectedDealerScopeLabel = useStore((state) => state.setSelectedDealerScopeLabel)
  const [showSlider, setShowSlider] = useState(false)

  const handleFilterByScope = (scope, label) => {
    if (dealerTableFilterParams['scope'] !== scope) {
      clearFilters()
      setDealerTableFilterParams({
        ...dealerTableFilterParams,
        "scope": scope
      })
      setSelectedLabel(label)
      setSelectedDealerScopeLabel(label)
    } else {
      clearFilters()
    }
  }

  const handleUtilizationCheckboxChange = () => {
    if (showSlider || (dealerTableFilterParams.hasOwnProperty('utilization[min]') && dealerTableFilterParams.hasOwnProperty('utilization[max]'))) {
      clearFilters()
    } else {
      clearFilters()
      setShowSlider(true)
    }
  }

  const clearFilters = () => {
    const { 'utilization[min]': _, 'utilization[max]': __, scope, ...remainingFilters } = dealerTableFilterParams
    setDealerTableFilterParams(remainingFilters)
    setSelectedLabel('')
    setSelectedDealerScopeLabel('')
    setShowSlider(false)
  }

  return(
    <Box p={4}>
      <Divider my={4} />
      <h4 className="font-semibold text-lg mb-2">Dealers with...</h4>
       <Stack>
        <Checkbox
          data-testid='untouched-units'
          isChecked={dealerTableFilterParams['scope'] === "with_untouched_units"}
          onChange={() => handleFilterByScope("with_untouched_units", "with Units Untouched 40+ Days")}
        >
          Units Untouched 40+ Days
        </Checkbox>
        <Checkbox
          data-testid='sold-units'
          isChecked={dealerTableFilterParams['scope'] === "with_sold_units"}
          onChange={() => handleFilterByScope("with_sold_units", "with Sold Units")}
        >
          Sold Units
        </Checkbox>
        <Checkbox
          data-testid='review-due'
          isChecked={dealerTableFilterParams['scope'] === "with_review_coming_due"}
          onChange={() => handleFilterByScope("with_review_coming_due", "with Review Coming Due")}
        >
          Review Coming Due
        </Checkbox>
        <Checkbox
          data-testid='repo-units'
          isChecked={dealerTableFilterParams['scope'] === "with_repo_units"}
          onChange={() => handleFilterByScope("with_repo_units", "with Repo Units")}
        >
          Repo Units
        </Checkbox>
        <Checkbox
          data-testid='past-due-units'
          isChecked={dealerTableFilterParams['scope'] === "with_past_due_units"}
          onChange={() => handleFilterByScope("with_past_due_units", "with Past Due Units")}
        >
          Past Due Units
        </Checkbox>
        <Checkbox
          data-testid='with-nsfs'
          isChecked={dealerTableFilterParams['scope'] === "with_nsfs"}
          onChange={() => handleFilterByScope("with_nsfs", "with Active NSFs")}
        >
          Active NSFs
        </Checkbox>
        <Checkbox
          data-testid='with-unreconciled-field-audits'
          isChecked={dealerTableFilterParams['scope'] === "with_unreconciled_field_audits"}
          onChange={() => handleFilterByScope("with_unreconciled_field_audits", "with Unreconciled Field Audits")}
        >
          Unreconciled Field Audits
        </Checkbox>
        <Checkbox
          data-testid='utilization'
          isChecked={showSlider || (dealerTableFilterParams.hasOwnProperty('utilization[min]') && dealerTableFilterParams.hasOwnProperty('utilization[max]'))}
          onChange={handleUtilizationCheckboxChange}
        >
          Utilization
        </Checkbox>
      </Stack>
      {(showSlider || (dealerTableFilterParams.hasOwnProperty('utilization[min]') && dealerTableFilterParams.hasOwnProperty('utilization[max]'))) && (
        <UtilizationFilter />
      )}
    <Divider my={4} />
    <h4 className="font-semibold text-lg mb-2">Proactive Filters</h4>
    <Stack>
      {/* <Checkbox
        data-testid='untouched-in-past-week'
        isChecked={dealerTableFilterParams['scope'] === "untouched_past_week"}
        onChange={() => handleFilterByScope("untouched_past_week", "Untouched In Past Week")}
      >
        Untouched In Past Week
      </Checkbox> */}
      <Checkbox
        data-testid='no-floors-this-week'
        isChecked={dealerTableFilterParams['scope'] === "no_floors_this_week"}
        onChange={() => handleFilterByScope("no_floors_this_week", "No Floors This Week")}
      >
        No Floors This Week
      </Checkbox>
      </Stack>
  </Box>
  )
}