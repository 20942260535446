import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Link
} from '@chakra-ui/react'
import useStore from '../../store'

export default function Breadcrumbs() {
  const breadcrumbTrail = useStore((state) => state.breadcrumbTrail)

  return(
    <Breadcrumb mb={4} pl={2}>
      {breadcrumbTrail.map((crumb, index) => (
        <BreadcrumbItem key={index}>
          <BreadcrumbLink as={Link} to={index === 0 ? '/' : '#'}>
            {crumb}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  )
}