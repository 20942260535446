import useStore from "../../../../store"
import LocationFilter from "./LocationFilter"
import FilterButtons from "./FilterButtons"
import FilterOptions from "./FilterOptions"
import tailwind from "../../../../../tailwind.config"
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
} from "@chakra-ui/react"

export default function FilterByDrawer({isOpen, onClose, handleResetFilter}) {
  const dealerTableFilterParams = useStore((state) => state.dealerTableFilterParams)
  const setDealerTableFilterParams = useStore((state) => state.setDealerTableFilterParams)
  const userLocation = useStore((state) => state.userLocation)
  const deepOcean = tailwind.theme.colors["deep-ocean"]

  const handleSubmit = () => {
    onClose()
  }

  const handleCancel = () => {
    onClose()
  }

  const handleFilterByRadius = (item) => {
    if(item.id && userLocation) {
      setDealerTableFilterParams({
        ...dealerTableFilterParams,
        "filter[distance]": item.id,
        "filter[lat]": userLocation.latitude,
        "filter[lng]": userLocation.longitude,
      })
    }
  }

  return(
    <Drawer isOpen={isOpen} placement='right' onClose={handleCancel}>
        <DrawerOverlay />
        <DrawerContent bg={deepOcean}>
          <DrawerCloseButton />
          <DrawerHeader>Filter Dealers</DrawerHeader>
          <DrawerBody>
            <LocationFilter
              handleFilterByRadius={handleFilterByRadius}
              dealerTableFilterParams={dealerTableFilterParams}
            />
            <FilterOptions
              setDealerTableFilterParams={setDealerTableFilterParams}
              dealerTableFilterParams={dealerTableFilterParams}
            />
          </DrawerBody>
          <DrawerFooter>
            <FilterButtons
              handleResetFilter={handleResetFilter}
              handleSubmit={handleSubmit}
            />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
  )
}