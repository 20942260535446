import { apiUrl, apiUrlV2 } from "../constants/apiUrl"
import { liveSupportEnabled } from '../utilities/featureFlags'
import { completedTransferParams } from "../constants/defaultTransferParams"
import { whiteListEnabled, isWhitelisted } from "../utilities/dealerWhitelist"

const initialState = {
  user: null,
  requestHeaders: null,
}

const createAuthSlice = (set, get) => ({
  ...initialState,
  webauthnId: null,
  askRegisterBiometric: true,
  loginUser: (userData) => set((state) => ({ user: userData })),
  loginTasks: (user) => {
    if (whiteListEnabled && !isWhitelisted(user)) {
      get().setErrMessage('Unable to login, please contact your Account Manager')
      return
    }

    get().loginUser(user)
    get().setRequestHeaders(user.token)
    if (liveSupportEnabled) {
      if (user.type === 'DealerUser') {
        get().loginChatUser()
      } else {
        get().loginSupportChatUser()
      }
    }

    if (user.type === 'DealerUser') {
      get().fetchPendingTransfers()
      get().fetchCompletedTransfers(completedTransferParams)
      get().fetchNotifications()
    }

    if (user.type === 'AccountManager') {
      get().fetchReminders()
    }
  },
  logoutUser: () => {
    if (liveSupportEnabled) {
      get().logoutChatUser()
    }
    get().resetAllSlices()
  },
  validateAzureToken: async (accessToken) => fetch(`${apiUrlV2}/omniauth/azure_token_validation`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ accessToken: accessToken })
  }),
  acceptInvitation: async (body) => fetch(`${apiUrl}/auth/accept-invitation`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  }),
  setWebauthnId: (id) => set((state) => ({ webauthnId: id })),
  setAskRegisterBiometric: (arb) => set((state) => ({ askRegisterBiometric: arb })),
  setRequestHeaders: (token) => set((state) => ({ requestHeaders: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }})),
  resetAuthSlice: () => set(initialState)
})

export default createAuthSlice