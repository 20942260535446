import { IoMdClose } from "react-icons/io"
import { FaEdit } from "react-icons/fa"

export default function ImagePreview({ image, onClose, onEdit }) {

  return(
    <div className="relative flex flex-col h-screen w-screen bg-black justify-center items-center">
      
        <IoMdClose  
          className="absolute text-3xl right-4 top-6 z-50 hover:cursor-pointer"
          onClick={() => onClose()}
          data-testid='preview-close'
        />
        <FaEdit  
          className="absolute text-3xl right-16 top-6 z-50 hover:cursor-pointer"
          onClick={() => onEdit()}
          data-testid="preview-edit"
        />

        <img src={image} alt="Image Preview" className="rounded" data-testid='image-preview' />
      
    </div>
  )
}