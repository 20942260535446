import useStore from "../../../../store"
import DealerRow from "../DealerRow"
import DealerCard from "./DealerCard"

export default function SavedDealersTab() {
  const savedDealers = useStore((state) => state.savedDealers)

  return(
    <div className="w-full bg-grey-900 rounded-t ">
      <h3 className="text-green font-semibold font-header p-2">
        Saved Dealers
      </h3>
      {savedDealers.length > 0 ?
        savedDealers.map((dealer) => (
          <DealerCard dealer={dealer} key={dealer.id}/>
        ))
        :
        <p className="text-grey-100 p-4">Select a dealer to add to your list</p>
      }
    </div>
  )
}