import { motion } from "framer-motion"
import InfoItem from "../../common/InfoItem"
import { DateTime } from "luxon"
import BackButton from "../../common/BackButton"

export default function NoteDetails({ note, onBack }) {
  const date = DateTime.fromISO(note.attributes['created-at'])

  return(
    <div className="overflow-x-hidden">
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        transition={{ type: 'tween', duration: 0.2 }}
      >
        <BackButton onClick={onBack} />

        <div className="w-full text-left bg-grey-900 rounded-t">
          <h3 className="font-header font-semibold text-md text-green">{`Note by ${note.attributes['created-by']} - ${date.toLocaleString()}`}</h3>
        </div>

        <div className="ml-1">
          <InfoItem title="Subject">
            {note.attributes.subject}
          </InfoItem>
          <InfoItem title="Note">
            {note.attributes.note}
          </InfoItem>
        </div>

      </motion.div>
    </div>
  )
}