import Spinner from "../../common/Spinner"
import useStore from "../../../store"
import InfoItem from "../../common/InfoItem"
import DropdownInfoItem from "../../common/DropdownInfoItem"
import EditableInfoItem from "../../common/EditableInfoItem"
import { useState, useEffect } from "react"
import { useIsMobile } from "../../../hooks/useIsMobile"
import { useNavigate } from "react-router-dom"
import ActionConfirm from "../../common/ActionConfirm"
import { FaEdit } from "react-icons/fa"
import EditDealerInfoButtons from "./EditDealerInfoButtons"
import ApprovedExceptions from "./ApprovedExceptions"
import formatter from "../../../constants/currencyFormatter"
import { DateTime } from "luxon"
import { isReviewDue } from "../../../utilities/unitUtilities"

export default function DealerInfo({dealerId}) {
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const dealer = useStore((state) => state.dealer)
  const dealerFetching = useStore((state) => state.dealerFetching)
  const suspendDealer = useStore((state) => state.suspendDealer)
  const unSuspendDealer = useStore((state) => state.unSuspendDealer)
  const updateDealer = useStore((state) => state.updateDealer)
  const [isEditingStatus, setIsEditingStatus] = useState(false)
  const [isEditingAttributes, setIsEditingAttributes] = useState(false)
  const [isUpdatingDealer, setIsUpdatingDealer] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [pendingStatus, setPendingStatus] = useState("")
  const [inputValues, setInputValues] = useState({})
  const lastReviewDate = dealer?.attributes["last-review-date"] && dealer.attributes["last-review-date"]
  const lastTouchDate = dealer?.attributes['last-touch-date']
    ? DateTime.fromISO(dealer.attributes['last-touch-date']).toLocaleString()
    : 'Never Touched'

  useEffect(() => {
    if (dealer) {
      setInputValues({
        licenseNumber: dealer.attributes["license-number"],
        email: dealer.attributes["email"],
        phone: dealer.attributes["primary-phone"].replace(/\D/g, ""),
        fax: dealer.attributes["primary-fax"].replace(/\D/g, ""),
        prefLabel1: dealer.attributes["preference-label-1"],
        prefText1: dealer.attributes["preference-text-1"],
        prefLabel2: dealer.attributes["preference-label-2"],
        prefText2: dealer.attributes["preference-text-2"],
        prefLabel3: dealer.attributes["preference-label-3"],
        prefText3: dealer.attributes["preference-text-3"],
      })
    }
  }, [dealer])

  const handleSave = (inputValue) => {
    setPendingStatus(inputValue)
    setShowConfirm(true)
  }

  const handleConfirmSave = () => {
    if (pendingStatus === "Suspended") {
      suspendDealer(dealer.id)
    } else {
      unSuspendDealer(dealer.id)
    }

    setIsEditingStatus(false)
    setShowConfirm(false)
  }

  const handleCancelSave = () => {
    setIsEditingStatus(false)
    setShowConfirm(false)
  }

  const handleSubmit = async () => {
    setIsUpdatingDealer(true)
    await updateDealer(dealer.id, {
      license_number: inputValues.licenseNumber,
      email: inputValues.email,
      primary_place_attributes: {
        phone: inputValues.phone,
        fax: inputValues.fax
      },
      preference_label_1: inputValues.prefLabel1,
      preference_text_1: inputValues.prefText1,
      preference_label_2: inputValues.prefLabel2,
      preference_text_2: inputValues.prefText2,
      preference_label_3: inputValues.prefLabel3,
      preference_text_3: inputValues.prefText3,
    })
    setIsEditingAttributes(false)
    setIsUpdatingDealer(false)
  }

  const handleEditAttributes = () => {
    if (isMobile) {
      navigate('edit')
    } else {
      setIsEditingAttributes(true)
    }
  }

  const statusDropdownOptions =
    dealer?.attributes["active-status"] === "Active" ? ["Suspended"] : ["Active"]

  const formatAuditDueDate = (date) => date === 'Not Scheduled' ? date : date && DateTime.fromISO(date).toLocaleString()

  return(
    <div className="flex flex-col items-center w-full">
      {(dealer && dealer?.id == dealerId) ?
        <div className="relative w-full lg:h-full bg-grey-900 p-2 mb-2 rounded justify-between">
          <div className="flex items-center justify-between pr-2">
            <h3 className="font-header font-semibold text-md text-green">Dealer Information</h3>
            {!isEditingAttributes &&
              <FaEdit
                className="hover:cursor-pointer"
                onClick={() => handleEditAttributes()}
                data-testid="edit-dealer-info"
                size={'20px'}
              />
            }
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-3 w-full text-left  bg-grey-900 rounded">
            <EditableInfoItem
              title={'Dealer #'}
              data={dealer.attributes["license-number"]}
              isEditing={isEditingAttributes}
              inputValue={inputValues.licenseNumber}
              onInputChange={(e) => setInputValues({ ...inputValues, licenseNumber: e.target.value })}
            />
            <InfoItem
              title={'AA ID#'}
              data={dealer.attributes["aa-id"]}
            />
            <InfoItem
              title={'Address'}
              data={dealer.attributes["primary-address1"]}
              subdata={dealer.attributes['city-state']}
            />
            <EditableInfoItem
              title={'Phone'}
              data={dealer.attributes["primary-phone"]}
              isEditing={isEditingAttributes}
              inputValue={inputValues.phone}
              onInputChange={(e) => setInputValues({ ...inputValues, phone: e.target.value })}
            />
            <EditableInfoItem
              title={'Fax'}
              data={dealer.attributes["primary-fax"]}
              isEditing={isEditingAttributes}
              inputValue={inputValues.fax}
              onInputChange={(e) => setInputValues({ ...inputValues, fax: e.target.value })}
            />
            <InfoItem
              title={'Title Audit Due'}
              data={formatAuditDueDate(dealer?.attributes["title-audit-due-on"])}
            />
            <InfoItem
              title={'Field Audit Due'}
              data={formatAuditDueDate(dealer?.attributes["field-audit-due-on"])}
            />
            <InfoItem
              title={'Activated'}
              data={dealer.attributes["active-at"] && DateTime.fromISO(dealer.attributes["active-at"]).toLocaleString()}
            />
            <InfoItem
              title={'First Floor'}
              data={dealer.attributes["first-floor-on"] && DateTime.fromISO(dealer.attributes["first-floor-on"]).toLocaleString()}
            />
            {!dealerFetching ?
              <DropdownInfoItem
              title={'Status'}
              data={dealer.attributes["active-status"]}
              dropdownOptions={statusDropdownOptions}
              dealerId={dealer.id}
              handleSave={handleSave}
              setIsEditing={setIsEditingStatus}
              isEditing={isEditingStatus}
              />
              :
              <Spinner/>
            }
            <InfoItem
              title={'Credit Available'}
              data={formatter.format(dealer.attributes["total-credit-remaining"])}
            />
            <InfoItem
              title={'Last Review Date'}
              data={dealer.attributes["last-review-date"] && DateTime.fromISO(dealer.attributes["last-review-date"]).toLocaleString()}
              alert={isReviewDue(lastReviewDate)}
            />
            <InfoItem
              title={'Last Touch Date'}
              data={lastTouchDate}
            />
          </div>
          <div className="w-full pt-2 pb-2 bg-grey-900 rounded-b">
            <EditableInfoItem
              title={'Email'}
              data={dealer.attributes.email}
              isEditing={isEditingAttributes}
              inputValue={inputValues.email}
              onInputChange={(e) => setInputValues({ ...inputValues, email: e.target.value })}
            />
          </div>
          <div className="w-full pt-2 pb-2 bg-grey-900 rounded-b">
            <div className="text-sm text-grey-300 mr-1 bg-grey-900 p-1">Dealer Preferences</div>
            <EditableInfoItem
              title={dealer.attributes["preference-label-1"]}
              titleValue={inputValues.prefLabel1}
              data={dealer.attributes["preference-text-1"]}
              isEditing={isEditingAttributes}
              inputValue={inputValues.prefText1}
              onInputChange={(e) => setInputValues({ ...inputValues, prefText1: e.target.value })}
              onTitleChange={(e) => setInputValues({ ...inputValues, prefLabel1: e.target.value})}
              editableTitle={true}
            />
            <EditableInfoItem
              title={dealer.attributes["preference-label-2"]}
              titleValue={inputValues.prefLabel2}
              data={dealer.attributes["preference-text-2"]}
              isEditing={isEditingAttributes}
              inputValue={inputValues.prefText2}
              onInputChange={(e) => setInputValues({ ...inputValues, prefText2: e.target.value })}
              onTitleChange={(e) => setInputValues({ ...inputValues, prefLabel2: e.target.value})}
              editableTitle={true}
            />
            <EditableInfoItem
              title={dealer.attributes["preference-label-3"]}
              titleValue={inputValues.prefLabel3}
              data={dealer.attributes["preference-text-3"]}
              isEditing={isEditingAttributes}
              inputValue={inputValues.prefText3}
              onInputChange={(e) => setInputValues({ ...inputValues, prefText3: e.target.value })}
              onTitleChange={(e) => setInputValues({ ...inputValues, prefLabel3: e.target.value})}
              editableTitle={true}
            />
          </div>
          <ApprovedExceptions
            message={dealer.attributes["approved-exceptions"]}
            approvedBy={dealer.attributes["approved-exceptions-by"]}
            approvedAt={dealer.attributes["approved-exceptions-at"]}
          />
          {isEditingAttributes &&
            <EditDealerInfoButtons
              setIsEditing={setIsEditingAttributes}
              isUpdating={isUpdatingDealer}
              onSubmit={handleSubmit}
            />
          }
        </div>
      :
        <Spinner />
      }

      {showConfirm && (
        <ActionConfirm
          body={`Are you sure you want to mark this dealer as ${pendingStatus}?`}
          onConfirm={handleConfirmSave}
          onCancel={handleCancelSave}
        />
      )}
    </div>
  )
}