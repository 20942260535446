import DealerName from "./DealerName"
import { useNavigate } from "react-router-dom"
import useStore from "../../../store"
import { DateTime } from "luxon"
import { useIsMobile } from "../../../hooks/useIsMobile"
import isValidVin from "../../../utilities/isValidVin"
import tailwind from "../../../../tailwind.config"
import SaveDealerIcon from "./SavedDealers/SaveDealerIcon"

export default function DealerRow({ dealer, filter, metrics, businessDevTableData=null, param, isNearbyDealersRow=null }) {
  const navigate = useNavigate()
  const resetUnitsAsAmSlice = useStore((state) => state.resetUnitsAsAmSlice)
  const resetDealerSlice = useStore((state) => state.resetDealerSlice)
  const dealerInState = useStore((state) => state.dealer)
  const lastTouchDate = DateTime.fromISO(dealer.attributes['last-touch-date']).toLocaleString()
  const setProspectDealerId = useStore((state) => state.setProspectDealerId)
  const prospectDealerId = useStore((state) => state.prospectDealerId)
  const setAmUnitsTableParams = useStore((state) => state.setAmUnitsTableParams)
  const dealerTableFilterParams = useStore((state) => state.dealerTableFilterParams)
  const fetchDealer = useStore((state) => state.fetchDealer)
  const isMobile = useIsMobile()
  const dealersSearchBarValue = useStore((state) => state.dealersSearchBarValue)
  const createListDealer = useStore((state) => state.createListDealer)
  const setDealerUnitsSearchBarValue = useStore((state) => state.setDealerUnitsSearchBarValue)
  const recentTouch = dealer.attributes['last-touch-date']
    ? DateTime.fromISO(dealer.attributes['last-touch-date']).toLocaleString()
    : 'Never Touched'
  const setBreadcrumbTrail = useStore((state) => state.setBreadcrumbTrail)
  const breadcrumbTrail = useStore((state) => state.breadcrumbTrail)
  const green = tailwind.theme.colors["green"]
  const savedDealers = useStore((state) => state.savedDealers)

  const handleProspectPage = () => {
    if (isMobile) {
      navigate(`/prospects/${dealer.id}`)
    } else {
      setProspectDealerId(dealer.id)
    }
  }

  const navToDealer = () => {
    if (businessDevTableData) {
      fetchDealer(dealer.id, true)

      handleProspectPage()
    } else {
      navigate(`/dealers/${dealer.id}`)
    }
  }

  const scopeHash = {
    'with_untouched_units': 'untouched_40_plus_days',
    'with_sold_units': 'sold',
    'with_repo_units': 'repo',
    'with_past_due_units': 'past_due'
  }

  const handleClick = () => {
    if(dealer.id !== dealerInState?.id) {
      resetUnitsAsAmSlice()
      resetDealerSlice()
    }

    if (['with_untouched_units', 'with_sold_units', 'with_repo_units', 'with_past_due_units'].includes(dealerTableFilterParams.scope) && !isNearbyDealersRow ) {
      setAmUnitsTableParams({ 'filter[scope]': scopeHash[dealerTableFilterParams['scope']] })
    } else {
      setAmUnitsTableParams('')
    }

    if(dealersSearchBarValue && isValidVin(dealersSearchBarValue)) {
      setDealerUnitsSearchBarValue(dealersSearchBarValue)
    }
    const newBreadcrumbTrail = [...breadcrumbTrail, dealer?.attributes.name]
    setBreadcrumbTrail(newBreadcrumbTrail)
    navToDealer()
  }

  const renderTableData = () => {
    const tableData = []
    if(!isNearbyDealersRow) {
      if (dealerTableFilterParams.scope === "with_untouched_units") {
        tableData.push(<td key="untouched" className="text-center">{dealer.attributes['untouched-unit-count']}</td>)
      }
      if (dealerTableFilterParams.scope === "with_sold_units") {
        tableData.push(<td key="sold" className="text-center">{dealer.attributes['sold-unit-count']}</td>)
      }
      if (dealerTableFilterParams.scope === "with_repo_units") {
        tableData.push(<td key="repo" className="text-center">{dealer.attributes['repo-unit-count']}</td>)
      }
      if (dealerTableFilterParams.scope === "with_past_due_units") {
        tableData.push(<td key="past-due" className="text-center">{dealer.attributes['past-due-unit-count']}</td>)
      }
      // if (dealerTableFilterParams.scope === "untouched_past_week") {
      //   tableData.push(<td key="past-due" className="text-center">{recentTouch}</td>)
      // }
    }


    if (dealer.attributes.distance !== null) {
      tableData.push(
        <td key="distance" data-testid='distance-column' className="text-center">{`${dealer.attributes.distance?.toFixed(2)} miles`}</td>
      )
    }

    if (metrics) {
      tableData.push(
        <>
          <td key="utilization" className="text-center">{`${dealer.attributes['current-utilization']}%`}</td>
          <td key="nap" className="text-center">{`${dealer.attributes['nap-count']}`}</td>
          <td key="unit-count" className="text-center">{`${dealer.attributes['unit-count']}`}</td>
        </>
      )
    }

    if (businessDevTableData) {
      if (param === 'status=2000') {
        tableData.push(
          <>
            <td key="last-touch" className="text-center">{dealer.attributes['last-touch-date'] ? lastTouchDate : 'N/A'}</td>
            <td key="touches" className="text-center">{dealer.attributes['number-of-touches']}</td>
          </>
        )
      }
      if (param === 'scope=awaiting_first_floor') {
        tableData.push(
          <>
            <td key="city-state" className="text-center">{dealer.attributes['city-state']}</td>
            <td key="active-status" className="text-center">{dealer.attributes['active-status']}</td>
          </>
        )
      }
      if (param === 'scope=first_floored_this_month') {
        tableData.push(
          <>
            <td key="unit-count-month" className="text-center">{dealer.attributes['unit-count']}</td>
            <td key="city-state-month" className="text-center">{dealer.attributes['city-state']}</td>
          </>
        )
      }
    }

    return tableData
  }

  const isDealerSaved = savedDealers.some((savedDealer) => savedDealer.id === dealer.id)

  return (
    <tr key={dealer.id} className={`border-b-2 border-grey-700 w-full hover:cursor-pointer lg:hover:bg-grey-700 ${prospectDealerId === dealer.id ? 'bg-grey-700': ''}`} onClick={() => {handleClick()}}>
      <td>
        <div className="flex w-full py-2 px-2 text-grey-300 items-center">
          <SaveDealerIcon dealer={dealer}/>
          <DealerName dealer={dealer} filter={filter} businessDevTableData={businessDevTableData}/>
        </div>
      </td>
      {renderTableData()}
    </tr>
  )
}
