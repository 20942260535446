import useStore from '../../../store'
import { useState, useEffect } from 'react'
import BusinessDevTableBody from './BusinessDevTableBody'
import BusinessDevTableHeader from './BusinessDevTableHeader'
import Spinner from '../../common/Spinner'
import ReactPaginate from 'react-paginate'
import { useIsMobile } from '../../../hooks/useIsMobile'
import AmSearchBar from '../../common/AmSearchBar'

export default function BusinessDevTable({tableName, tableType, param, searchTerm, setSearchTerm}) {
  const businessDevTableData = useStore((state) => state.businessDevTableData)
  const businessDevTableFetching = useStore((state) => state.businessDevTableFetching)
  const fetchBusinessDevTableData = useStore((state) => state.fetchBusinessDevTableData)
  const businessDevTableParam = useStore((state) => state.businessDevTableParam)
  const businessDevTableFilterAttribute = useStore((state) => state.businessDevTableFilterAttribute)
  const businessDevTableFilterDirection = useStore((state) => state.businessDevTableFilterDirection)
  const setBusinessDevTableFilterAttribute = useStore((state) => state.setBusinessDevTableFilterAttribute)
  const setBusinessDevTableFilterDirection = useStore((state) => state.setBusinessDevTableFilterDirection)
  const setBusinessDevTablePage = useStore((state) => state.setBusinessDevTablePage)
  const businessDevTablePage = useStore((state) => state.businessDevTablePage)
  const dataTotal = useStore((state) => state.paginationData.dataTotal)
  const lastPage = useStore((state) => state.paginationData.lastPage)
  const totalPages = useStore((state) => state.paginationData.totalPages)
  const isMobile = useIsMobile()
  const currentPage = useStore((state) => state.paginationData.currentPage)

  useEffect(() =>{
    fetchBusinessDevTableData()
  }, [businessDevTableFilterAttribute, businessDevTableFilterDirection, businessDevTableParam, businessDevTablePage])

  const canRender = () => {
    return tableName && tableType && param
  }

  const handlePageClick = (event) => {
    setBusinessDevTablePage(event.selected + 1)
  }

  const handleSearch = (value) => {
    setSearchTerm(value)
    setBusinessDevTableFilterAttribute(`search=${value}`)
  }

  const handleResetFilter = () => {
    setBusinessDevTableFilterAttribute('')
    setBusinessDevTableFilterDirection('')
    setSearchTerm('')
  }

  return (
    <>
      {canRender() &&
        <>
        <div className="w-full relative bg-grey-900 rounded-t mt-2">
          <div className="p-2 z-30 font-header font-semibold text-md text-green mr-1 top-0 flex justify-between w-full pr-3 bg-grey-900 sticky" data-testid="inner-business-dev-table-name">
            {tableName}
          </div>
            <div className="relative flex flex-col w-full px-2 text-left items-center">
              {businessDevTableFetching &&
                <div className="absolute top-0 left-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50">
                  <Spinner />
                </div>
              }
              <AmSearchBar
                showReset={businessDevTableFilterAttribute}
                handleReset={handleResetFilter}
                handleSearch={handleSearch}
              />
              <table className="table-fixed w-full">
                <BusinessDevTableHeader
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  param={param}
                />
                <BusinessDevTableBody
                  searchTerm={searchTerm}
                  data={businessDevTableData}
                  param={param}
                  tableType={tableType}
                />
              </table>
              {!businessDevTableFetching &&
                <ReactPaginate
                  className="flex justify-center pt-8 pb-8"
                  previousLabel={currentPage > 0 ? 'previous' : ''}
                  nextLabel={currentPage < lastPage ? 'next' : ''}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={Math.ceil(dataTotal / 20)}
                  marginPagesDisplayed={isMobile ? 1 : 2}
                  pageRangeDisplayed={isMobile ? 3 : 5}
                  onPageChange={handlePageClick}
                  forcePage={currentPage}
                  pageLinkClassName={"relative inline-flex items-center  bg-grey-900 px-4 py-2 text-sm font-medium text-grey-100 hover:bg-grey-300 hover:text-black hover:rounded cursor-pointer"}
                  nextLinkClassName={
                    `relative inline-flex items-center  bg-grey-900 px-2 py-2 text-sm font-medium text-grey-100 hover:bg-grey-300 hover:text-black focus:z-20 hover:rounded cursor-pointer ${
                      currentPage >= totalPages - 1 ? 'invisible' : ''
                    }`
                  }
                  previousLinkClassName={
                    `relative inline-flex items-center  bg-grey-900 px-2 py-2 text-sm font-medium text-grey-100 hover:bg-grey-300 hover:text-black focus:z-20 hover:rounded cursor-pointer ${
                      currentPage <= 0 ? 'invisible' : ''
                    }`
                  }
                  activeLinkClassName={
                    `relative inline-flex items-center rounded border border-grey-100 bg-grey-300 px-2 py-2 text-sm font-medium text-black focus:z-20`
                  }
                />
              }
            </div>
          </div>
        </>
      }
    </>
  )
}