import HighlightMatch from "../../../utilities/HighlightMatch"
import unitStatus from "../../../utilities/unitStatus"

export default function UnitName({ unit, filter }) {
  const color = unitStatus(unit).unitNameStyle

  return(
    <div className="font-bold">
      <div className={`text-sm ${color}`}>
        <HighlightMatch str={`${unit.attributes.year} ${unit.attributes.make}`} match={filter} truncate={true}/>
      </div>
      <div className={`text-lg ${color || 'text-grey-100'}`}>
        <HighlightMatch str={unit.attributes.model} match={filter} truncate={true}/>
      </div>
    </div>
  )
}