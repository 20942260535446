import useStore from "../../../store"
import { useState } from "react"
import DealersTableBody from "./DealersTableBody"
import DealersTableHeader from './DealersTableHeader'
import Spinner from '../../common/Spinner'
import NoDealersFound from './NoDealersFound'
import AmSearchBar from "../../common/AmSearchBar"
import { IoFilterSharp } from "react-icons/io5"
import { useDisclosure } from "@chakra-ui/react"
import FilterByDrawer from "./DealerTableFiltering/FilterByDrawer"
import FilterIndicators from "./DealerTableFiltering/FilterIndicators"

export default function DealerTable() {
  const dealersFetching = useStore((state) => state.dealersFetching)
  const dealers = useStore((state) => state.dealers)
  const setDealerFilterAttribute = useStore((state) => state.setDealerFilterAttribute)
  const setDealerFilterDirection = useStore((state) => state.setDealerFilterDirection)
  const dealerFilterAttribute = useStore((state) => state.dealerFilterAttribute)
  const [searchTerm, setSearchTerm] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const dealerTableFilterParams = useStore((state) => state.dealerTableFilterParams)
  const setDealerTableFilterParams = useStore((state) => state.setDealerTableFilterParams)
  const userLocation = useStore((state) => state.userLocation)

  const handleSearch = (value) => {
    setSearchTerm(value)
    setDealerFilterAttribute(`search=${value}`)
  }

  const handleResetFilter = () => {
    setDealerFilterDirection('')
    setSearchTerm('')
    setDealerTableFilterParams('')
    setDealerFilterAttribute('default')
    onClose()
  }

  const getSelectedUtilFilterName = (params) => {
    const min = params['utilization[min]']
    const max = params['utilization[max]']

    if (min === undefined || max === undefined) {
      return 'No filter selected'
    }

    return `Util ${min}% - ${max}%`
  }

  const selectedUtilFilterName = getSelectedUtilFilterName(dealerTableFilterParams)

  return (
    <div className="w-full relative bg-grey-900 rounded-t">
      <div className="p-2 z-30 font-header font-semibold mr-1 top-0 flex justify-between w-full pr-3 bg-grey-900 sticky">
        <h3 className="font-header font-semibold text-md text-green">{'Dealers'}</h3>
        <IoFilterSharp data-testid='filter-button' onClick={onOpen} style={{ cursor: "pointer" }} />
      </div>
      <div className="flex flex-col w-full px-2 text-left items-center">
        <AmSearchBar
          handleSearch={handleSearch}
          handleReset={handleResetFilter}
          showReset={dealerFilterAttribute !== 'default' || Object.keys(dealerTableFilterParams).length > 0}
        />
        <FilterIndicators
          dealerTableFilterParams={dealerTableFilterParams}
          selectedUtilFilterName={selectedUtilFilterName}
          setDealerTableFilterParams={setDealerTableFilterParams}
          onClose={onClose}
        />
        <table className="table-fixed w-full">
          <DealersTableHeader searchTerm={searchTerm} setSearchTerm={setSearchTerm} style={"top-[72px]"}/>
          <DealersTableBody searchTerm={searchTerm}  />
        </table>
        {dealersFetching && <div className="p-2"><Spinner /></div>}
        {!dealersFetching && dealers.length === 0 && <NoDealersFound />}
      </div>
      <FilterByDrawer
        isOpen={isOpen}
        onClose={onClose}
        handleResetFilter={handleResetFilter}
      />
    </div>
  )
}
