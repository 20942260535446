import useStore from "../../../../store"
import { useEffect, useState } from "react"
import TouchesTable from "./TouchesTable"
import TouchForm from "./TouchForm"

export default function Touches({ dealerId }) {
  const fetchTouches = useStore((state) => state.fetchTouches)
  const [showTouchForm, setShowTouchForm] = useState(false)

  useEffect(() => {
    fetchTouches(dealerId)
  }, [])

  return(
    <div>
      <div className="w-full text-left bg-grey-900 rounded-t pl-2">
        <h3 className="font-header font-semibold text-md text-green">Touches</h3>
      </div>
      {showTouchForm ?
        <TouchForm dealerId={dealerId} setShowTouchForm={setShowTouchForm} /> :
        <TouchesTable setShowTouchForm={setShowTouchForm} />
      }
    </div>
  )
}