import { motion } from "framer-motion"
import useStore from "../../../store"
import { Box, FormControl, FormLabel, Input, Textarea } from '@chakra-ui/react'
import { useState } from "react"
import tailwind from "../../../../tailwind.config"
import { HappyButton, NeutralButton } from "../../common/Buttons"
import Spinner from "../../common/Spinner"
import BackButton from "../../common/BackButton"

export default function NoteForm({ type, id, setShowNoteForm }) {
  const createNote = useStore((state) => state.createNote)
  const isCreatingNote = useStore((state) => state.isCreatingNote)
  const [inputValues, setInputValues] = useState({ subject: '', note: '' })
  const grey300 = tailwind.theme.colors["grey-300"]

  const handleSubmit = async () => {
    await createNote(type, id, inputValues)
    setShowNoteForm(false)
  }

  return(
    <div className="overflow-x-hidden">
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        transition={{ type: 'tween', duration: 0.2 }}
      >

        <div className="w-full text-left bg-grey-900 rounded-t">
          <h3 className="font-header font-semibold text-md text-green">{`New Note`}</h3>
        </div>

        <BackButton onClick={() => setShowNoteForm(false)} />

        <Box as="form" mt={2} ml={4} onSubmit={handleSubmit}>
          <FormControl id="subject" mb={2}>
            <FormLabel fontSize={'sm'} color={grey300} htmlFor="subject">Subject</FormLabel>
            <Input
              value={inputValues.subject}
              onChange={(e) => setInputValues({ ...inputValues, subject: e.target.value })}
              focusBorderColor="white"
            />
          </FormControl>
          <FormControl id="note" mb={2}>
            <FormLabel fontSize={'sm'} color={grey300} htmlFor="note">Note</FormLabel>
            <Textarea
              value={inputValues.note}
              onChange={(e) => setInputValues({ ...inputValues, note: e.target.value })}
              focusBorderColor="white"
            />
          </FormControl>
          <div className="flex justify-evenly items-center">
            {isCreatingNote ?
              <Spinner color="green" /> :
              <>
                <HappyButton
                  text={'Create Note'}
                  onClick={handleSubmit}
                  style="my-4 w-30 justify-items-center"
                />
                <NeutralButton
                  text='Cancel'
                  onClick={() => setShowNoteForm(false)}
                  style="my-4 w-30 justify-items-center"
                />
              </>
            }
          </div>
        </Box>
      </motion.div>
    </div>
  )
}