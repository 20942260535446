import { CometChatUIKit } from "@cometchat/chat-uikit-react"
import { cometChatApiUrl } from "../constants/apiUrl"

const authKey = import.meta.env.VITE_COMETCHAT_AUTH_KEY
const apiKey = import.meta.env.VITE_COMETCHAT_API_KEY

const initialState = {
  isLoggingInChatUser: false,
}

const createCometChatSlice = (set, get) => ({
  ...initialState,
  loginChatUser: async () => {
    const dealerUser = get().user

    const user = await CometChatUIKit.getLoggedinUser()
    if (!user) {
      try {
        const loginUser = await CometChatUIKit.login(dealerUser.dealerId, authKey)
        console.log("Login Successful:", { loginUser })
      } catch (err) {
        if (err.code === 'ERR_UID_NOT_FOUND') {
          get().createAndLoginChatUser()
        } else {
          console.error(err)
        }
      }
    }
  },
  loginSupportChatUser: async () => {
    set((state) => ({ isLoggingInChatUser: true }))
    const user = await CometChatUIKit.getLoggedinUser()
    if (user) {
      await get().logoutChatUser()
    }
    
    try {
      const loginUser = await CometChatUIKit.login('customerservice', authKey)
      console.log("Login Successful:", { loginUser })
    } catch (err) {
      console.error(err)
    } finally {
      set((state) => ({ isLoggingInChatUser: false }))
    }
  },
  logoutChatUser: async () => {
    const user = await CometChatUIKit.getLoggedinUser()
    if (user) {
      try {
        await CometChatUIKit.logout()
        console.log('Logout completed successfully')
      } catch (err) {
        console.error("Logout failed with exception:", { err })
      }
    }
  },
  createAndLoginChatUser: async () => {
    const user = get().user
    try {
      const newChatUser = await fetch(`${cometChatApiUrl}/users`, {
        method: 'POST',
        headers: { apiKey: apiKey, 'Content-Type': 'application/json' },
        body: JSON.stringify({
          uid: user.dealerId,
          name: user.lotname,
          role: 'dealer',
          metadata: { branchId: user.branchId, phoneNumber: user.text_message_number }
        })
      })
      console.log('Successfully created user.', { newChatUser })
      get().loginChatUser()
    } catch (err) {
      console.error(err)
    }
  },
  resetCometChatSlice: () => set(initialState)
})

export default createCometChatSlice