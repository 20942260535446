import useStore from "../../store"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import SupportChat from "./SupportChat"
import { liveSupportEnabled } from "../../utilities/featureFlags"

export default function SupportDashboard() {
  const logoutUser = useStore((state) => state.logoutUser)
  const isLoggingInChatUser = useStore((state) => state.isLoggingInChatUser)
  const { t } = useTranslation()

  if (liveSupportEnabled) {
    return(
      <div className="flex flex-col min-h-dvh max-h-dvh">
        <div className="flex grow">
          {!isLoggingInChatUser && <SupportChat />}
        </div>
        <div
          className="text-center text-fpx-red hover:cursor-pointer"
          onClick={() => logoutUser()}
        >
          {t('Logout')}
        </div>
      </div>
    )
  } else {
    return(
      <div className="flex flex-col justify-evenly text-center min-h-dvh max-h-dvh">
        <div className="flex">
          Thank you for logging into Triple B. The support dashboard is still under construction. 
        </div>
        <div
          className="text-center text-fpx-red hover:cursor-pointer"
          onClick={() => logoutUser()}
        >
          {t('Logout')}
        </div>
      </div>
    )
  }
}