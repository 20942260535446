import useStore from "../../../store"
import DealerRow from "../Dealers/DealerRow"
import DealersTableHeader from "../Dealers/DealersTableHeader"
import { useState } from "react"

export default function NearbyDealers() {
  const dealer = useStore((state) => state.dealer)
  const nearbyDealers = useStore((state) => state.nearbyDealers)
  const [searchTerm, setSearchTerm] = useState('')

  return(
    <div className="flex flex-col items-center sm:mt-0 w-full bg-grey-900">
      <div className="w-full sticky z-30 top-0 left-0 w-full font-bold text-grey-100 text-left rounded-t bg-grey-900">
        <div className="flex w-full items-center pt-2 pb-3 px-2">
            <h3 className="font-header font-semibold text-md text-green">{`Dealers Near ${dealer?.attributes.name}`}</h3>
        </div>
      </div>
      <table className="table-fixed w-full">
        <DealersTableHeader searchTerm={searchTerm} setSearchTerm={setSearchTerm} isNearbyDealersRow={true} style={"top-[44px]"} />
        <tbody>
          {nearbyDealers.map((dealer) => (
            <DealerRow dealer={dealer} key={dealer.id} filter={null} metrics={true} isNearbyDealersRow={true}/>
          ))}
        </tbody>
      </table>
    </div>
  )
}