import { useState } from "react"
import { useDebouncedCallback } from 'use-debounce'
import { FaUndoAlt } from "react-icons/fa"
import SearchBar from "./SearchBar"
import useStore from "../../store"

export default function AmSearchBar({ handleSearch, handleReset, showReset }) {
  const fetchDealers = useStore((state) => state.fetchDealers)
  const setDealerFilterAttribute = useStore((state) => state.setDealerFilterAttribute)
  const dealersSearchBarValue = useStore((state) => state.dealersSearchBarValue)
  const setDealersSearchBarValue = useStore((state) => state.setDealersSearchBarValue)
  const dealerTableFilterParams = useStore((state) => state.dealerTableFilterParams)

  const debouncedSearch = useDebouncedCallback(
    (value) => {
      handleSearch(value)
    },
    500
  )

const handleChange = (value) => {
    setDealersSearchBarValue(value)
    debouncedSearch(value)
  }

  const handleResetClick = () => {
    handleReset()
    setDealersSearchBarValue('')
    fetchDealers()
    setDealerFilterAttribute('default')
  }

  const handleSearchBarBlur = () => {
    setDealersSearchBarValue('')
  }

  return(
    <div
      className="sticky top-10 h-8 flex w-full items-center bg-grey-900 text-left text-md text-grey-300 mx-1 hover:cursor-pointer"
    >
      {showReset &&
        <FaUndoAlt
          onClick={handleResetClick}
          className="text-md text-grey mr-2"
          data-testid="search-reset"
        />
      }
      <div className="w-full">
        <SearchBar
          onChange={handleChange}
          value={dealersSearchBarValue}
          onBlur={handleSearchBarBlur}
        />
      </div>
    </div>
  )
}