import { useState, useEffect } from "react"
import { CometChatMessageHeader, CometChatMessageComposer, CometChatMessageList } from "@cometchat/chat-uikit-react"
import { CometChat } from "@cometchat/chat-sdk-javascript"
import { ListItemStyle } from "@cometchat/uikit-elements"
import {
  MessageHeaderStyle,
  MessageComposerStyle,
  MessageListStyle,
} from "@cometchat/uikit-shared"
import tailwind from "../../../../tailwind.config"
import { FaChevronLeft } from "react-icons/fa"

export default function Chat() {
  const grey900 = tailwind.theme.colors["grey-900"]
  const grey = tailwind.theme.colors["grey"]
  const grey300 = tailwind.theme.colors["grey-300"]
  const grey100 = tailwind.theme.colors["grey-100"]
  const font = tailwind.theme.fontFamily["sans"]
  const [customerServiceUser, setCustomerServiceUser] = useState(null)

  useEffect(() => {
    CometChat.getUser('customerservice')
      .then((user) => {
        setCustomerServiceUser(user)
      })
  }, [])

  const mhStyle = new MessageHeaderStyle({
    borderRadius: '0.25rem',
    background: grey900,
    subtitleTextFont: font,
    subtitleTextColor: grey300,
  })

  const mcStyle = new MessageComposerStyle({
    background: grey900,
    borderRadius: '0.25rem',
    inputBorder: `2px solid ${grey300}`,
    textColor: grey100,
    textFont: font,
    placeHolderTextFont: font,
    placeHolderTextColor: grey,
    attachIcontint: grey,
    emojiIconTint: grey,
    voiceRecordingIconTint: grey,
    sendIconTint: grey300
  })

  const mlStyle = new MessageListStyle({
    height: '100%',
    TimestampTextColor: grey300,
    nameTextColor: grey300,
    threadReplyTextColor: grey300,
    loadingIconTint: grey300,
    emptyStateTextColor: grey300
  })

  const lStyle = new ListItemStyle({
    background: "transparent",
    titleColor: grey100,
    titleFont: font
  })

  const dateStyle = {
    textColor: grey300
  }

  return(
    <div className="flex flex-col min-h-dvh max-h-dvh">
      {customerServiceUser &&
        <div className="flex flex-col min-h-dvh max-h-dvh">
          <div className="flex items-center top-0 w-full bg-grey-900 rounded-b">
            <FaChevronLeft 
              className="text-xl text-grey-300 ml-2" 
              onClick={() => window.history.back()}
            />
            <CometChatMessageHeader 
              user={customerServiceUser}
              messageHeaderStyle={mhStyle}
              listItemStyle={lStyle}
              hideBackButton={true}
              subtitleView={(user) => null}
            />
          </div>
          <div className="flex flex-col-reverse overflow-y-auto grow items-center">
            <CometChatMessageList 
              user={customerServiceUser}
              messageListStyle={mlStyle}
              dateSeparatorStyle={dateStyle}
              scrollToBottomOnNewMessages={true}
            />
          </div>
          <div className="bottom-0 w-full">
            <CometChatMessageComposer 
              user={customerServiceUser}
              messageComposerStyle={mcStyle}
            />
          </div> 
        </div>
      }
    </div>
  )
}