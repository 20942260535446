import { apiUrl, apiUrlV2 } from "../constants/apiUrl"
import createSearchParams from "../utilities/createSearchParams"

const initialState = {
  dealers: [],
  dealer: undefined,
  dealersFetching: false,
  dealerFilterAttribute: 'default',
  dealerFilterDirection: 'filter[direction]=DESC',
  dealerTableFilterParams: '',
  dealerPaginationData: {},
  selectedDealerScopeLabel: null,
  dealersSearchBarValue: '',
  isUtilizationSelected: false,
  dealersPage: 1,
  nearbyDealers: [],
  nearbyDealersFetching: false,
  nearbyDealerPaginationData: {}
}

const createDealersSlice = (set, get) => ({
  ...initialState,
  setDealerFilterAttribute: (attribute) => {
    set((state) => ({ dealerFilterAttribute: attribute}))
  },
  setDealerFilterDirection: (direction) => {
    set((state) => ({ dealerFilterDirection: direction}))
  },
  setDealerTableFilterParams: (params) => {
    set((state) => ({dealerTableFilterParams: params}))
  },
  setSelectedDealerScopeLabel: (params) => {
    set((state) => ({selectedDealerScopeLabel: params}))
  },
  setDealersSearchBarValue: (params) => {
    set((state) => ({dealersSearchBarValue: params}))
  },
  setIsUtilizationSelected: (boolean) => {
    set((state) => ({isUtilizationSelected: boolean}))
  },
  setDealersPage: (page) => {
    set((state) => ({dealersPage: page}))
  },
  fetchDealers: async (params) => {
    set((state) => ({ dealersFetching: true}))
    try {
      const response = await fetch(
        `${apiUrlV2}/dealers?page=${get().dealersPage}&${get().dealerFilterAttribute}&${get().dealerFilterDirection}&${createSearchParams(params)}`,
        {headers: get().requestHeaders}
      )
      if (response.status === 200) {
        const data = await response.json()

        if(get().user) {
          set((state) => ({
            dealers: data.data,
            dealersFetching: false,
            dealerPaginationData: {
              dealerResultsCount: response.headers.get('x-total'),
              lastPage: response.headers.get('x-last-page'),
              totalPages: response.headers.get('x-total-pages'),
              currentPage: Number(response.headers.get('x-page')) - 1
            }
          }))
        }
      }
    } catch (err) {
      get().setErrMessage('Data was unable to be fetched. Please refresh to try again')
    }
  },
  fetchNearbyDealers: async (lat, lng, distance) => {
    set((state) => ({ nearbyDealersFetching: true}))
    try {
      const response = await fetch(
        `${apiUrlV2}/dealers?page=${get().dealersPage}&filter[lat]=${lat}&filter[lng]=${lng}&filter[distance]=${distance}`,
        {headers: get().requestHeaders}
      )
      if (response.status === 200) {
        const data = await response.json()

        if(get().user) {
          set((state) => ({
            nearbyDealers: data.data,
            nearbyDealersFetching: false,
            nearbyDealerPaginationData: {
              dealerResultsCount: response.headers.get('x-total'),
              lastPage: response.headers.get('x-last-page'),
              totalPages: response.headers.get('x-total-pages'),
              currentPage: Number(response.headers.get('x-page')) - 1
            }
          }))
        }
      }
    } catch (err) {
      get().setErrMessage('Data was unable to be fetched. Please refresh to try again')
    }
  },

  resetDealersSlice: () => set(initialState)
})

export default createDealersSlice
