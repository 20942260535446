import useStore from "../../../store"
import { useTranslation } from "react-i18next"
import { Payoff, Refloor, OtherPayment } from "../../common/PayoffRefloorPills"
import formatter from "../../../constants/currencyFormatter"
import formatAccountNumber from "../../../utilities/accountNumber"
import InfoItem from "../../common/InfoItem"
import { DateTime } from "luxon"

export default function TransferPageBody() {
  const { t } = useTranslation()
  const transfer = useStore((state) => state.transfer)
  const transferDate = DateTime.fromISO(transfer.attributes['actual-transfer-date'])

  const transferType = () => {
    switch(transfer.attributes['payment-type']) {
      case 'Refloor':
        return <Refloor />
      case 'Payoff':
        return <Payoff />
      default:
        return <OtherPayment type={transfer.attributes['payment-type']} />
    }
  }

  // Transfer status is either 'Locked' or 'In Progress'
  const transferIsProcessing = () => [2, 3].includes(transfer.attributes.status)

  return(
    <>
      <div className="flex flex-col overflow-y-auto ml-2">
        <div>{transferType()}</div>
        <div className= 'text-4xl text-grey-100 mt-2'>
          {formatter.format(transfer.attributes.amount)}
        </div>
        <div className="text-lg text-grey-100 mb-2">
          {`${transfer.attributes.make} ${transfer.attributes.model}`}
        </div>
        {transferIsProcessing() &&
          <div className="flex flex-col items-center my-2 w-full text-yellow border border-yellow rounded">
            {t('This transaction is currently being processed.')}
          </div>
        }
        <InfoItem title="VIN">{transfer.attributes.vin}</InfoItem>
        <InfoItem title="Initiated by">{transfer.attributes['created-by-name']}</InfoItem>
        <InfoItem title="Date">{transferDate.toFormat('M/d/yy')}</InfoItem>
        <InfoItem title="Account">{`${transfer.attributes['account-type']} ${formatAccountNumber(transfer.attributes['safe-act'])}`}</InfoItem>
        <InfoItem title="Account Nickname">{transfer.attributes['account-name-for-dealer']}</InfoItem>
        <InfoItem title="Breakdown">
          <div className="flex flex-col pl-4">
            <div>{`Principal - ${formatter.format(transfer.attributes['principal-paid'])}`}</div>
            <div>{`Interest - ${formatter.format(transfer.attributes['interest-paid'])}`}</div>
            <div>{`Fees - ${formatter.format(parseFloat(transfer.attributes['fees-paid']) + parseFloat(transfer.attributes['insurance-fees-paid']))}`}</div>
            {transfer.attributes['reserves-added'] > 0 &&
              <div>{`Reserves Added - ${formatter.format(transfer.attributes['reserves-added'])}`}</div>
            }

          </div>
        </InfoItem>
      </div>
    </>
  )
}