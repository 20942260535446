import { Td, Tr } from "@chakra-ui/react"
import { DateTime } from "luxon"

export default function TouchRow({ touch }) {
  return(
    <Tr>
      <Td className="w-1/4 overflow-hidden text-ellipsis whitespace-nowrap max-w-xs">
        {touch.attributes.type}
      </Td>
      <Td className="w-1/4 overflow-hidden text-ellipsis whitespace-nowrap max-w-xs">
        {DateTime.fromISO(touch.attributes['touch-on']).toLocaleString()}
      </Td>
      <Td className="w-1/4 overflow-hidden text-ellipsis whitespace-nowrap max-w-xs">
        {touch.attributes['created-by']}
      </Td>
    </Tr>
  )
}