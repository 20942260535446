import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import Spinner from './components/common/Spinner'
import './index.css'
import './i18n'
import { PublicClientApplication, EventType } from "@azure/msal-browser"
import { msalConfig } from "./configs/msalAuthConfig.js"
import { chakraTheme } from './configs/chakraConfig.js'
import { registerSW } from 'virtual:pwa-register'
import {
  ChakraBaseProvider,
  ColorModeScript,
} from '@chakra-ui/react'

/**
* MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
* For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
*/
const msalInstance = new PublicClientApplication(msalConfig)

msalInstance.addEventCallback((event) => {
    if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      event.payload.account
    ) {
      msalInstance.setActiveAccount(event.payload.account)
    }
})

const intervalMS = 30 * 60 * 1000

registerSW({
  onRegisteredSW(swUrl, r) {
    r && setInterval(async () => {
      if (!(!r.installing && navigator))
        return

      if (('connection' in navigator) && !navigator.onLine)
        return

      const resp = await fetch(swUrl, {
        cache: 'no-store',
        headers: {
          'cache': 'no-store',
          'cache-control': 'no-cache',
        },
      })

      if (resp?.status === 200) {
        await r.update()
      }
    }, intervalMS)
  },
  immediate: true
})

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ChakraBaseProvider theme={chakraTheme}>
      <React.Suspense fallback={<div className="flex w-full h-screen justify-center items-center"><Spinner /></div>}>
        <ColorModeScript initialColorMode={chakraTheme.config.initialColorMode} />
          <App msalInstance={msalInstance} />
        </React.Suspense>
    </ChakraBaseProvider>
  </React.StrictMode>,
)
