import TransferPageBody from "./TransferPageBody"

export default function TransferPage() {
  return(
    <div className="flex w-full grow lg:justify-center">
      <div className="flex flex-col overflow-y-auto ml-2">
        <TransferPageBody />
      </div>
    </div>
  )
}