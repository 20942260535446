import useStore from "../../../store"
import { useEffect, useState } from "react"
import NotesRow from "./NotesRow"
import NoteDetails from "./NoteDetails"
import NoteForm from "./NoteForm"
import Pagination from "../../common/Pagination"
import { FaPlusCircle } from "react-icons/fa"

// Notes Body type is either "dealers", "units", or "audits"
export default function NotesBody({ type, id }) {
  const notes = useStore((state) => state.notes)
  const setNotesPage = useStore((state) => state.setNotesPage)
  const { currentPage, lastPage, dataTotal, totalPages } = useStore((state) => state.notesPaginationData)
  const [selectedNote, setSelectedNote] = useState(null)
  const [showNoteForm, setShowNoteForm] = useState(false)

  useEffect(() => {
    setSelectedNote(null)
  }, [notes])

  const handlePageClick = (event) => {
    setNotesPage(event.selected + 1)
  }

  if (selectedNote) {
    return(
      <NoteDetails
        note={selectedNote}
        onBack={() => setSelectedNote(null)}
      />
    )
  } else if (showNoteForm) {
    return(
      <NoteForm
        type={type}
        id={id}
        setShowNoteForm={setShowNoteForm}
      />
    )
  } else {
    return(
      <div className="flex flex-col w-full bg-grey-900 p-2">
        <div className="w-full flex items-center justify-between bg-grey-900 rounded-t">
          <h3 className="font-header font-semibold text-md text-green">Notes</h3>
          <div
            className="flex items-center hover:cursor-pointer"
            onClick={() => setShowNoteForm(true)}
            data-testid='create-note'
          >
            <FaPlusCircle size={'24px'}/>
          </div>
        </div>
        {notes.map((n) => {
          return(
            <NotesRow key={n.id} note={n} onClick={() => setSelectedNote(n)} />
          )
        })}
        <Pagination
          currentPage={currentPage}
          lastPage={lastPage}
          dataTotal={dataTotal}
          handlePageClick={handlePageClick}
          totalPages={totalPages}
        />
      </div>
    )
  }
}