import { Button } from "@chakra-ui/react"
import useStore from "../../../../store"

export default function FilterIndicators({setDealerTableFilterParams, onClose, dealerTableFilterParams, selectedUtilFilterName}) {
  const selectedDealerScopeLabel = useStore((state) => state.selectedDealerScopeLabel)

  const updateFilterParams = (paramsToRemove) => {
    const updatedFilterParams = { ...dealerTableFilterParams }
    paramsToRemove.forEach(param => delete updatedFilterParams[param])
    setDealerTableFilterParams(updatedFilterParams)
  }

  const handleRemoveUtilFilter = () => {
    updateFilterParams(['utilization[min]', 'utilization[max]'])
    onClose()
  }

  const handleResetRadius = () => {
    updateFilterParams(['filter[distance]', 'filter[lat]', 'filter[lng]'])
    onClose()
  }

  const handleResetDealerScope = () => {
    updateFilterParams(['scope'])
    onClose()
  }

  return(
    <div className="flex justify-start w-full gap-2 mb-2 mt-2">
      {dealerTableFilterParams['utilization[min]'] && dealerTableFilterParams['utilization[max]'] && (
        <Button
          variant="outline"
          borderColor={'white'}
          color={'white'}
          border={'2px'}
          bg="transparent"
          onClick={handleRemoveUtilFilter}
          sx={{
            borderRadius: 'full'
          }}
        >
          {selectedUtilFilterName}
        </Button>
      )}
      {dealerTableFilterParams['filter[distance]'] && (
        <Button
          variant="outline"
          borderColor={'white'}
          color={'white'}
          border={'2px'}
          bg="transparent"
          onClick={handleResetRadius}
          sx={{
            borderRadius: 'full'
          }}
        >
          {`${dealerTableFilterParams['filter[distance]']} miles`}
        </Button>
      )}
      {dealerTableFilterParams['scope'] && (
        <Button
          variant="outline"
          borderColor={'white'}
          color={'white'}
          border={'2px'}
          bg="transparent"
          onClick={handleResetDealerScope}
          sx={{
            borderRadius: 'full'
          }}
        >
          {selectedDealerScopeLabel}
        </Button>
      )}
    </div>
  )
}