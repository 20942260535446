import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
} from '@chakra-ui/react'
import TouchRow from "./TouchRow"
import useStore from '../../../../store'
import tailwind from "../../../../../tailwind.config"
import { FaPlusCircle } from "react-icons/fa"


export default function TouchesTable({ setShowTouchForm }) {
  const touches = useStore((state) => state.touches)
  const grey300 = tailwind.theme.colors["grey-300"]

  return(
    <>
      <div
        className="flex items-center ml-2 hover:cursor-pointer"
        onClick={() => setShowTouchForm(true)}
      >
        <FaPlusCircle />
        <div className="ml-1.5">Create Touch</div>
      </div>
      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th color={grey300}>Type</Th>
              <Th color={grey300}>Date</Th>
              <Th color={grey300}>Touched By</Th>
            </Tr>
          </Thead>
          <Tbody>
            {touches && touches.map((touch) => (
              <TouchRow key={touch.id} touch={touch} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  )
}