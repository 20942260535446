import { useState, useEffect, useCallback } from "react"
import useStore from "../../../store"
import { useTranslation } from "react-i18next"
import { NeutralButton } from "../../common/Buttons"
import ImagePreview from "./ImagePreview"
import NAPCamera from "./NAPCamera"
import SwipeAnimation from "../../common/SwipeAnimation"

export default function NAPPicture({ title }) {
  const { t } = useTranslation()
  const [cameraOpen, setCameraOpen] = useState(false)
  const [previewOpen, setPreviewOpen] = useState(false)
  const image = useStore((state) => state.NAPImages[title])
  const nextStep = useStore((state) => state.nextStep)

  useEffect(() => {
    if (!image) {
      setCameraOpen(true)
    }
  }, [])

  const imagePreview = () => {
    return(
      <div className="flex flex-col items-center justify-between h-full">
        <div className="flex flex-col items-center mt-6">
          <div
            className="w-full h-[45vh] rounded"
            onClick={() => setPreviewOpen(true)}
            data-testid='photo-preview'
          >
            <img src={image} alt="Preview" className="w-full h-full object-contain rounded" />
          </div>

          <div
            className="text-fpx-red text-lg my-2"
            onClick={() => setCameraOpen(true)}
          >
              {t('Retake')}
          </div>
        </div>

        <NeutralButton
          text={t("Use Picture")}
          onClick={() => nextStep()}
          style="text-lg mb-4"
        />
      </div>
    )
  }

  return(
    <SwipeAnimation>
      <div className="fixed top-0 left-0 z-50">
        {cameraOpen && <NAPCamera
          close={() => setCameraOpen(false)}
          title={title}
        />}
        {previewOpen && <ImagePreview
          image={image}
          onClose={() => setPreviewOpen(false)}
          onEdit={() => {
            setPreviewOpen(false)
            setCameraOpen(true)
          }}
        />}
      </div>

      <div className="flex flex-col items-center pt-6 rounded w-full h-full">
        <div className="text-2xl mb-4">{title}</div>

        {image ?
          imagePreview() :
          <NeutralButton
            text={t("Take Picture")}
            onClick={() => setCameraOpen(true)}
            style="absolute bottom-0 my-4 text-lg"
          />
        }
      </div>
    </SwipeAnimation>
  )
}