export function HappyButton({ text, onClick, style = "" }) {
  return(
    <div
      onClick={() => onClick()}
      className={`flex justify-center items-center text-center text-grey-100 hover:opacity-75 hover:cursor-pointer py-1.5 px-8 border border-2 border-green rounded-full ${style}`}
    >
      {text}
    </div>
  )
}

export function NeutralButton({ text, onClick, style = "", dataTestId=null }) {
  return(
    <div
      onClick={(e) => {
        e.stopPropagation()
        onClick(e)
      }}
      className={`flex justify-center items-center text-center text-grey-100 hover:opacity-75 hover:cursor-pointer py-1.5 px-8 border border-2 rounded-full ${style}`}
      data-testid={dataTestId}
    >
      {text}
    </div>
  )
}

export function RedButton({ text, onClick, style = "" }) {
  return(
    <div
      onClick={() => onClick()}
      className={`${style} flex justify-center items-center text-center text-grey-100 hover:opacity-75 hover:cursor-pointer py-1.5 px-8 border border-2 border-fpx-red rounded-full`}
    >
      {text}
    </div>
  )
}

export function SolidGreenButton({ text, onClick, style = "" }) {
  return(
    <div
      onClick={() => onClick()}
      className={`${style} flex justify-center items-center bg-green text-center text-grey-100 hover:opacity-75 hover:cursor-pointer py-1.5 px-8 border border-2 border-green rounded-full`}
    >
      {text}
    </div>
  )
}
