import { apiUrl, apiUrlV2 } from "../constants/apiUrl"

const initialState = {
  notes: [],
  isFetchingNotes: false,
  isCreatingNote: false,
  notesPaginationData: {},
  notesPage: 1,
}

const createNotesSlice = (set, get) => ({
  ...initialState,
  fetchNotes: async (type, id) => {
    set(() => ({ isFetchingNotes: true }))
    try {
      const response = await fetch(
        `${apiUrlV2}/${type}/${id}/notes?page=${get().notesPage}`,
        { headers: get().requestHeaders }
      )
      const data = await response.json()

      set(() => ({
        notes: data.data,
        notesPaginationData: {
          dataTotal: response.headers.get('x-total'),
          lastPage: response.headers.get('x-last-page'),
          totalPages: response.headers.get('x-total-pages'),
          currentPage: Number(response.headers.get('x-page')) - 1
        }
      }))
    } catch (err) {
      console.log(err)
      get().setErrMessage('Unable to retrieve notes at this time.')
    } finally {
      set(() => ({ isFetchingNotes: false }))
    }
  },
  createNote: async (type, id, body) => {
    set(() => ({ isCreatingNote: true }))
    try {
      const response = await fetch(
        `${apiUrlV2}/${type}/${id}/notes`,
        {
          headers: get().requestHeaders,
          method: 'POST',
          body: JSON.stringify(body)
        }
      )
      const data = await response.json()

      if (response.status === 200) {
        get().setSuccessMessage('Note successfully created')
        get().fetchNotes(type, id)
      } else {
        get().setErrMessage(data.errors[0])
      }
    } catch (err) {
      console.log(err)
      get().setErrMessage('There was an error creating this note. Please try again.')
    } finally {
      set(() => ({ isCreatingNote: false }))
    }
  },
  setNotesPage: (page) => {
    set((state) => ({ notesPage: page }))
  },
  resetNotesSlice: () => set(initialState)
})

export default createNotesSlice