import { apiUrl, apiUrlV2 } from "../constants/apiUrl"

const initialState = {
  dealersListFetching: false,
  savedDealers: []
}

const createDealersListSlice = (set, get) => ({
  ...initialState,
  fetchListDealers: async() => {
    set((state) => ({ dealersListFetching: true}))

    try {
      const response = await fetch(
        `${apiUrlV2}/dealers/my_dealers`,
        {headers: get().requestHeaders}
      )
      if (response.status === 200) {
        const data = await response.json()

        if(get().user) {
          set((state) => ({
            savedDealers: data.data,
            dealersListFetching: false,
          }))
        }
      }
    } catch (err) {
      get().setErrMessage('Data was unable to be fetched. Please refresh to try again')
    }
  },
  createListDealer: async (dealerId) => {
    try {
      await fetch(
        `${apiUrlV2}/list_dealers`, {
          method: 'POST',
          headers: get().requestHeaders,
          body: JSON.stringify({ list_dealer: { dealer_id: dealerId } }),
        }
      )
      get().fetchListDealers()
    } catch (err) {
      get().setErrMessage('Dealer was unable to be saved. Please refresh to try again')
    }
  },
  removeListDealer: async (listDealerId) => {
    try {
      await fetch(
        `${apiUrlV2}/list_dealers/${listDealerId}`, {
          method: 'DELETE',
          headers: { 'Authorization': get().requestHeaders['Authorization'] },
        }
      )
      get().fetchListDealers()
    } catch (err) {
      get().setErrMessage('Dealer was unable to be removed. Please refresh to try again')
    }
  }
})

export default createDealersListSlice