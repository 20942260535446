import { apiUrl, apiUrlV2 } from "../constants/apiUrl"
import sortUnitsByNapIssues from "../utilities/sortUnitsByNapIssues"
import createSearchParams from "../utilities/createSearchParams"

const initialState = {
  units: [],
  unit: undefined,
  comingDueUnits: [],
  soldUnits: [],
  amountDueOn: {},
  isFetchingAmountDue: false,
  unitsFetching: false,
  unitFetching: false,
  dashboardUnitId: null,
  amUnitsTableParams: '',
  dealerUnitsSearchBarValue: '',
}

const createDealerUnitsSlice = (set, get) => ({
  ...initialState,
  setDealerUnitsSearchBarValue: (params) => {
    set((state) => ({dealerUnitsSearchBarValue: params}))
  },
  setAmUnitsTableParams: (params = {}) => {
    set((state) => ({
      amUnitsTableParams: {
        'filter[scope]': 'current',
        ...params,
      }
    }))
  },

  setUnitsAsAmFetching: (boolean) => {
    set((state) => ({ unitsAsAmFetching: boolean }))
  },
  fetchUnitsAsAm: async (dealerId, params = {}) => {
    set((state) => ({ unitsFetching: true }))

    const searchParams = {
      'filter[scope]': 'current',
      ...params,
    }

    try {
      const response = await fetch(
        `${apiUrlV2}/dealers/${dealerId}/units?${createSearchParams(searchParams)}`,
        { headers: get().requestHeaders }
      )
      const data = await response.json()

      if (get().user) {
        const units = data.data
        if (units?.some((u) => u.attributes['nap-issues'].length > 0)) {
          sortUnitsByNapIssues(units)
        }
        set((state) => ({ units: units }))
      }
    } catch (err) {
      get().setErrMessage('Unit data was not able to be fetched. Please refresh to try again')
    } finally {
      set(() => ({ unitsFetching: false }))
    }
  },
  resetUnitsAsAmSlice: () => set(initialState)
  })

  export default createDealerUnitsSlice
