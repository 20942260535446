import useStore from "../../store"
import { useEffect } from "react"
import NotificationRow from "./NotificationRow"
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next"

export default function Notifications() {
  const { t } = useTranslation()
  const notifications = useStore((state) => state.notifications)
  const fetchNotifications = useStore((state) => state.fetchNotifications)

  useEffect(() => {
    fetchNotifications()
  }, [])

  return(
    <div className="flex grow overflow-y-auto justify-center w-full">
      <div className="flex flex-col overflow-y-auto overflow-x-hidden w-full lg:w-1/2 lg:bg-grey-900 lg:h-[85vh]">
        <div className="sticky flex items-center top-0 left-0 z-30 w-full pt-2 pb-3 px-2 bg-black lg:bg-grey-900 rounded-t">
          <h3 className="font-header font-semibold text-md text-green mr-1">
            {t("Notifications")}
          </h3>
        </div>
        <motion.div
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          transition={{ type: 'tween', duration: 0.2 }}
          className="w-full"
        >
          {notifications &&
            notifications.map((n) => {
              return(
                <NotificationRow notification={n} key={n.id} />
              )
            })
          }
        </motion.div>
      </div>
    </div>
  )
}