import { apiUrlV2 } from "../constants/apiUrl"

const initialState = {
  notifications: [],
  notification: null,
}

const createNotificationsSlice = (set, get) => ({
  ...initialState,
  setNotification: (notification) => {
    set(() => ({ notification: notification }))
  },
  fetchNotifications: async () => {
    try {
      const response = await fetch(
        `${apiUrlV2}/notifications`,
        { headers: get().requestHeaders }
      )
      const data = await response.json()
      set(() => ({ notifications: data.data }))
    } catch (err) {
      get().setErrMessage('Unable to retrieve notifications at this time.')
    }
  },
  markNotificationAsRead: async (id) => {
    try {
      const response = await fetch(
        `${apiUrlV2}/notifications/${id}/mark_read`,
        {
          headers: get().requestHeaders,
          method: 'PATCH'
        }
      )
    } catch (err) {
      console.log(err)
    } finally {
      get().fetchNotifications()
    }
  },
  resetNotificationsSlice: () => set(initialState)
})

export default createNotificationsSlice